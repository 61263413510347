import { actions } from "./fakturaAdminActions";

const initialState = {
    kisIntegration: '',
    invoiceList: [],
    filteredList: [],
    fetchedData: [],
    apiTimeStamp: '',
    filterString: '',
    antall: 0,
    sum_alle: 0.0,
    antall_valgte: 0,
    antall_ikke_valgte: 0,
    antall_saker_valgte: 0,
    sum_valgte: 0.0,
    sum_ikke_valgte: 0.0,
    hasFakturaAdmin: false,
    fetched: false,
    loading: false,
    error: ''
}

/*

"klist": {
    "invoiceList": [
      {
        "invoiceNumber": "string",
        "customerReference": "string",
        "invoiceAmount": 0,
        "invoiceDate": "string",
        "dueDate": "string",
        "kid": "string",
        "currencyCode": "string",
        "invoiceText": "string",
        "debitor": {
          "debitorId": "string",
          "debitorName": "string",
          "debitorCode": "string",
          "email": "string",
          "cellPhone": "string",
          "customerType": "string",
          "address": {
            "streetAddress": "string",
            "city": "string",
            "zipCode": "string",
            "countryCode": "string",
            "streetAddressList": [
              "string"
            ]
          }
        }
      }
    ]
  },

  */

let setCounters = (newState) => {

    //let newState = { ...state }
            
    let count = 0
    let total = 0.0
    let countSelected = 0
    let countNotSelected = 0
    let sumSelected = 0.0
    let sumNotSelected = 0.0
    let countCasesSelected = 0
    const uniqueNumbers = new Set(); 

    newState.invoiceList.forEach(e => {

        if (!e.disabled) {

            count++
            total += e.invoiceAmount
            
            if (e.isSelected) {
                countSelected++
                sumSelected += e.invoiceAmount
            if (!uniqueNumbers.has(e.debitorId)) {
                uniqueNumbers.add(e.debitorId);
                countCasesSelected++;
            }
        } else {
            countNotSelected++;
            sumNotSelected += e.invoiceAmount;
        }
        }
            
    })

    newState.antall = count
    newState.sum_alle = total
    newState.sum_valgte = sumSelected
    newState.sum_ikke_valgte = sumNotSelected
    newState.antall_valgte = countSelected
    newState.antall_ikke_valgte = countNotSelected
    newState.antall_saker_valgte = countCasesSelected
    
    return newState

}

export default function fakturaAdminReducer(state = initialState, action) {

    switch (action.type) {

        case actions.FAKTURA_ADM_LOADING:
            return {
                ...state,
                filteredList: [],
                fetched: false,
                loading: true,
                error: null
            };
        
        case actions.FAKTURA_ADM_CLEAR_LOADING:
            return {
                ...state,
                fetched: false,
                loading: false,
                error: null
            }
        
        case actions.FAKTURA_ADM_SET_KISINTEGRATION:
            return {
                ...state,
                kisIntegration: action.payload.kisIntegration
            }

        case actions.FAKTURA_ADM_SET_SOKEVERDI:            
            let _filteredList = []
            if (!action.payload.value) {
                _filteredList = [...state.invoiceList]
            }
            else {
                _filteredList = [...state.invoiceList.filter((rec) => rec.searchIndex.includes(action.payload.value.toUpperCase()))]
            }
            return {
                ...state,
                filterString: action.payload.value,
                filteredList: _filteredList
            }

        case actions.FAKTURA_ADM_SET_APITIMESTAMP:
            return {
                ...state,
                apiTimeStamp: action.payload.value
            }

        case actions.FAKTURA_ADM_DATA_FETCHED:
            let fetchedState = {
                ...state,
                filterString: '',
                invoiceList: [...action.payload.invoiceList],
                filteredList: [...action.payload.invoiceList],
                fetchedData: [...action.payload.fetchedData],
                hasFakturaAdmin: true,
                fetched: true,
                loading: false,
                error: null
            };

            // Filtrer vekk de som ikke skal vises som default.
            // Viser default kun de som er 15 dager eller mer fra forfall
            const _filteredFetchedList = action.payload.invoiceList.filter(e => e.passedDueDate === true);
            fetchedState.invoiceList = _filteredFetchedList

            return setCounters(fetchedState)
        
        case actions.FAKTURA_ADM_DATA_CLEAR:

            let emptyState = {
                ...state,
                kisIntegration: '',
                invoiceList: [],
                fetchedData: [],
                filteredList: [],
                hasFakturaAdmin: false,
                fetched: true,
                loading: false,
                error: null
            };
            return setCounters(emptyState)

        case actions.FAKTURA_ADM_ERROR:
            return {
                ...state,
                loading: false,
                fetched: true,
                error: action.data
            }
        
        case actions.FAKTURA_ADM_TOGGLE_SELECTED:
            let newState = {
                ...state,
                loading: false,
                fetched: true,
                error: null
            }
            newState.filteredList.forEach(e => {
                if (!e.disabled) {                    
                    if (e.i === action.payload.i) {
                        e.isSelected = action.payload.value
                    }
                }
            })
            newState.invoiceList.forEach(e => {
                if (!e.disabled) {                    
                    if (e.i === action.payload.i) {
                        e.isSelected = action.payload.value
                    }
                }
            })

            return setCounters(newState)
        
        case actions.FAKTURA_ADM_DISABLE_SELECTED:
            let newState2 = {
                ...state,
                loading: false,
                fetched: true,
                error: null
            }
            newState2.invoiceList.forEach(e => {
                if (e.isSelected) {
                    e.isSelected = false
                    e.disabled = 'disabled'
                }
            })
            
            // TODO filrert liste er tom når man kommer hit
            newState2.filteredList.forEach(e => {
                if (e.isSelected) {
                    e.isSelected = false
                    e.disabled = 'disabled'
                }
            })
            
            let _filteredListX = []
            if (!action.payload.value) {
                _filteredListX = [...state.invoiceList]
            }
            else {
                _filteredListX = [...state.invoiceList.filter((rec) => rec.searchIndex.includes(action.payload.value.toUpperCase()))]
            }
            newState2.filteredList = _filteredListX
            return setCounters(newState2)
        
        case actions.FAKTURA_ADM_TOGGLE_ALL:
            let allState = {
                ...state,
                loading: false,
                fetched: true,
                error: null
            }
            allState.invoiceList.forEach(e => {
                if (!e.disabled) {
                    e.isSelected = action.payload.value                    
                }
            })

            return setCounters(allState)

            case actions.FAKTURA_ADM_TOGGLE_PASSED_DUE_DATE:
                let onlyState = {
                    ...state,
                    loading: false,
                    fetched: true,
                    error: null
                }
                
                // filteredList inneholder original data og forblir uendret, setter invoiceList til den
                // når toggle = off, filtrerer ut de med passedDuedate = false om toggle = on
                const _filteredDueDateList = !action.payload.value
                    ? state.invoiceList.filter(e => e.passedDueDate === true)
                    : [...state.filteredList];
         
                onlyState.invoiceList = _filteredDueDateList
                return setCounters(onlyState)                
        default:
            return state;
    }
}
