export const messages = {
    nb: {
        header: `Brukerprofiler`,
        beskrivelse: `Her vises brukerprofiler du søker etter.`,   
        beskrivelseEtterSøk: `Følgende brukerprofiler ble funnet. Klikk på en av dem for å gå videre til detaljer for denne brukerprofilen.`,   
        Epostadresse: 'Epostadresse',
        PhoneNumber: 'Mobiltelefon',
        Navn: 'Navn',
        Kreditor: 'Kreditor',
        Kreditorer: 'Ant kreditorer',
        Tilgangsnivå: 'Systemtilgang',
        Forening: 'Forening',
        CustomerNo: 'Kreditorid',
        OrgNo: 'Organisasjonsnr',
        Disabled: 'Deaktivert',
        LastLogin: 'Sist pålogget',

        Locked: 'Brukeren er låst, rediger og lagre for å låse opp.',
        AccessFailedCounts: 'Antall forsøk med feil passord.',

        Supervisor: 'Systemadministrator',
        UserAdmin: 'Foreningsadministrator',
        CustomerAdmin: 'Konsernbruker',
        User: 'Bruker',
        WSUser: 'WS Bruker',

        Tilbake: 'Tilbake',
        Delete: 'Slett',
        Confirm: 'Bekreft',
        Cancel: 'Avbryt',
        DeleteUser: 'Slett bruker',
        DeleteUsers: 'Slett brukere',
        PressConfirm: 'Trykk på Bekreft for å fullføre sletting',
        ExpandUsers: 'Klikk for å vise valgte brukere',
        HideUsers: 'Klikk for å skjule valgte brukere',
    }
} 
