export const messages = {
    nb: {
        header: `Glemt passord`,
        beskrivelse: `Oppgi nytt passord i begge passordfeltene og trykk på knappen nederst.`,
        Lagre: `Oppdater passord`,

        youHaveErrors: 'Du har feil i skjemaet, ikke alle felter er fylt ut korrekt.  Du må korrigere dette før du kan endre profilen din.',
       
        toastSaveError: 'Kunne ikke oppdatere passord pga: ',
        toastSaveOk: 'Passorder er din er oppdatert.',

        IsRequired: `Feltet er påkrevd.`,
        
        validatePassword: 'Passord må ha minst 8 tegn, både store og små bokstaver, tall og spesialtegn. ',
        checkEqualPasswords: 'Passordene oppgitt er forskjellige',

        Password1Label: `Nytt Passord`,
        Password1Placeholder: `Oppgi nytt passord`,
        Password1Small: `Oppgi passordet du vil endre til her.`,
        Password1Required: `Du må oppgi passord.`,

        Password2Label: `Gjenta nytt passord`,
        Password2Placeholder: `Gjenta nytt passord`,
        Password2Small: `Gjenta passordet du evt vil endre til her.`,


    }
} 