import React, { useState } from 'react';
import CaseDetailsContext from '../../CaseDetailsContext'

import { StyledKfTable, StyledKfTextTable } from '../../../../Styling/styledTable/StyledTable';
import UseTableSorter from '../../../../components/useTable/UseTable'
import usePaginator from '../../../../components/useTable/UsePaginator'

import { IntlProvider, FormattedMessage, FormattedDate, FormattedNumber } from 'react-intl'

// Deprecated
const CaDetNotaterComponent = (props) => {

    const { state, dispatch, localeMessages } = React.useContext(CaseDetailsContext);

    // Felter
    const f1 = 'caseNoteDate'
    const f2 = 'caseNoteText'
    

    // State for grid sorting
    const [currentSeq, setCurrentSeq] = useState('down'); // default
    const [currentField, setCurrentField] = useState(f1);    // feltnavn
    const [currentType, setCurrentType] = useState('datetime'); // num

    let pageSize = 1000
    if (!props.isReport) {
        pageSize = 10
    }

    // state for grid paginator
    const [numberPrPage, setNumberPrPAge] = useState(pageSize)
    const [activePage, setActivePage] = useState(1);

    const [t1ChangeSortField, t1GetSortFunc, t1IndicaterIcon] = UseTableSorter(
        currentSeq, setCurrentSeq,
        currentField, setCurrentField,
        currentType, setCurrentType
    )

    const gridData = state.data.notes

    const [Paginate, PaginationNav, PaginationDropdown, PaginationIndex, resetPagination] = usePaginator(
        gridData.sort(t1GetSortFunc().fn), // <==== fix henting av data
        numberPrPage, setNumberPrPAge,
        activePage, setActivePage
    )

    const getPageHeader = (fld) => localeMessages['notesLst_' + fld]

    const asDate = (val) => {
        return (
            <FormattedDate value={val} day="2-digit" month="2-digit" year="numeric" />
        )
    }

    const asDecimal = (val) => {
        if (val) {

            return (
                <FormattedNumber
                    value={val}
                    style='decimal'
                    minimumFractionDigits={2}
                />
            )
        }
        else {
            return ''
        }
    }

    if (props.isReport && (state.data.notes.length === 0)) {
        return (
            <></>
        )
    }

    return (
        <>
            <StyledKfTextTable>
                <thead>
                    <tr>
                        <th onClick={() => t1ChangeSortField(f1, 'datetime')} style={{ width: '150px'}}>{getPageHeader('f1')}{t1IndicaterIcon(f1)}</th>
                        <th onClick={() => t1ChangeSortField(f2, 'string')}>{getPageHeader('f2')}{t1IndicaterIcon(f2)}</th>                        
                        <th></th>
                    </tr>
                </thead>

                <tbody>

                    <Paginate>
                        {row =>
                            <tr key={row['i']}>
                                <td>{asDate(row[f1])}</td>
                                <td>{row[f2]}</td>                                
                                <td></td>
                            </tr>
                        }
                    </Paginate>

                </tbody>

            </StyledKfTextTable>
            <br />
            {(!props.isReport) && <><PaginationNav /><PaginationIndex /></>}
        </>
    )
}

export default CaDetNotaterComponent
