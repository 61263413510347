import React, {useState} from 'react';
import { IntlProvider, FormattedMessage } from 'react-intl'
import LocaleContext from '../../../state/contexts/LocaleContext'
import { messages } from './SearchComponent.i18n'

import { ArticleArea } from '../../../Styling/Layout/ContentArea'
import { Container, Col, Form } from "react-bootstrap";
import { BfYellowRowForm, BfTightYellowRowForm, YellowRoundedContainer, BfRowForm, BfTightRowForm, BfFormLabel, BfFormLabelSm, BfFormControl, BfButtonBlue, BfSelect } from '../../../Styling/styledForm/styledBlueForm'
import { Spinner } from '../../../components/spinner/Spinner'
import { useSelector } from 'react-redux';


const SearchComponent = (props) => {

    const locale = React.useContext(LocaleContext);
    const { SearchFieldPlaceholder, SearcValuePlaceholder, velgFraListe, brukerNavn, epostAdresse, kundenummer, kundenavn, orgNummer } = messages[locale]
    
    //console.log(props.state)
    const [values, setValues] = useState({ searchField: props.state.searchForm.searchField, searchValue: props.state.searchForm.searchValue })
    
    const handleInputChange = e => {
        const { name, value } = e.target
        setValues({ ...values, [name]: value })
    }

    const credsState = useSelector(state => state.sakslisteHeader)

    // { "email", "fullName", "orgNummer", "kundeNavn", "kundeNummer" };
    /*
    const options = [
        { value: 'fullName', label: brukerNavn },
        { value: 'email', label: epostAdresse},
        { value: 'kundeNummer', label: kundenummer },
        { value: 'orgNummer', label: orgNummer },        
    ]*/

    const clearForm = () => setValues({ searchField: '', searchValue: '' })
    const createNew = () => props.createUser()
    const isAdminKreditor = () => props.isAdminKreditor()
    const copyKonserAdmin = () => props.copyKonserAdmin(credsState.kreditorliste)

    if (props.loading) {
        return (<Spinner />)
    }
    
    return (
        <IntlProvider locale={locale} messages={messages[locale]}>
            <Container>

                <ArticleArea>
                    <h1>{<FormattedMessage id="header" />}</h1>
                    <p>{<FormattedMessage id="beskrivelse" />}</p>
                    <br />
                </ArticleArea>
            </Container>
            
            <YellowRoundedContainer fluid="md" style={{padding: '15px'}}>
                
                <BfYellowRowForm>
                    <Col xs={12} md={12}>                          
                        <Form.Group controlId="formSearchField">
                            <BfFormLabel><FormattedMessage id="SearchFieldLabel" /></BfFormLabel>
                            
                            <BfSelect as="select"
                                name='searchField'
                                placeholder={SearchFieldPlaceholder}
                                onChange={handleInputChange}
                                value={values.searchField}
                            >
                                <option value='velg'>{velgFraListe}</option>
                                <option value='fullName'>{brukerNavn}</option>
                                <option value='email'>{epostAdresse}</option>
                                <option value='kreditorname'>{kundenavn}</option>
                                <option value='CustomerNo'>{kundenummer}</option>
                                <option value='orgNo'>{orgNummer}</option>
                                
                            </BfSelect>

                            <BfFormLabelSm className="text-muted">
                            <FormattedMessage id="SearchFieldSmall" />
                            </BfFormLabelSm>
                        </Form.Group>                                                                                                      
                    </Col>
                </BfYellowRowForm>

                <BfYellowRowForm>
                    <Col xs={12} md={12}>
                        <Form.Group controlId="formSearcValue">
                            <BfFormLabel><FormattedMessage id="SearcValueLabel" /></BfFormLabel>
                            
                            <BfFormControl type="input" name='searchValue' placeholder={SearcValuePlaceholder}  
                                onChange={handleInputChange}
                                value={values.searchValue} 
                            />

                            <BfFormLabelSm className="text-muted">
                            <FormattedMessage id="SearcValueSmall" />
                            </BfFormLabelSm>
                        </Form.Group>                                                        
                    </Col>
                </BfYellowRowForm>

                <BfTightYellowRowForm>
                    <Col xs={12} md={12}>

                    <BfButtonBlue
                        variant="primary"
                        onClick={() => {
                            props.searchUsers(values.searchField, values.searchValue);
                            props.onSearch();
                        }}
                        disabled={!(values.searchValue && values.searchField)}>
                        <FormattedMessage id="SokOk" />
                    </BfButtonBlue>
                        &nbsp;
                        <BfButtonBlue variant="primary" type="submit" onClick={() => createNew()}>
                            <FormattedMessage id="OpprettNy" />
                        </BfButtonBlue>
                        &nbsp;

                        {isAdminKreditor() && 
                            <BfButtonBlue variant="primary" type="submit" onClick={() => copyKonserAdmin()}>
                                <FormattedMessage id="KopierMeg" />
                            </BfButtonBlue>
                        }

                    </Col>

                </BfTightYellowRowForm>

                <BfTightYellowRowForm />
            </YellowRoundedContainer>                    
            
        </IntlProvider>
    )
}

export default SearchComponent
