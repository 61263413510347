import React, { useContext, useEffect, useState, useReducer, useCallback } from 'react';
import { IntlProvider, FormattedMessage } from 'react-intl'
import LocaleContext from '../../state/contexts/LocaleContext'
import { messages } from './UserAdminContainer.i18n'
import styled, { keyframes, css } from 'styled-components'

//import { ArticleArea } from '../../Styling/Layout/ContentArea'
//import { Container, Row, Col, Button, Form, InputGroup, FormControl } from "react-bootstrap";
//import { BfRowForm, BfTightRowForm, BfRowSpacer, BfFormLabel, BfFormLabelSm, BfFormControl, BfButtonBlue } from '../../Styling/styledForm/styledBlueForm'
import  EditProfile  from './editProfile/EditProfile'
//import styled from 'styled-components'
import SearchComponent from './search/SearchComponent';
import SearchResultComponent from './searchResult/SearchResultComponent'
//import useAxios from '../../hooks/useAxios'
import useUserAdminReducer from './useUserAdminReducer'
import { Alert } from "react-bootstrap";

import { mockData } from '../../assets/Brukeradmin'
import { Spinner } from '../../components/spinner/Spinner'

const SearchContainer = styled.div`
    display: flex;
    gap: 20px;
    align-items: flex-start;
`;

const SearchSection = styled.div`
    flex: ${({ fullWidth }) => (fullWidth ? '1' : '0.31')};
    min-width: 300px;
    transition: flex 0.5s ease;
`;

const slideIn = keyframes`
    from {
        opacity: 0;
        transform: translateX(100%);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
`;

const ResultSection = styled.div`
    flex: 0.7;
    min-width: 300px;
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    transition: opacity 0.5s ease;
    ${({ animate }) =>
        animate &&
        css`
            animation: ${slideIn} 0.5s ease-out forwards;
        `}
    display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
`;

const UserAdminContainer = (props) => {

    const locale = React.useContext(LocaleContext);

    const { state, displayForms, resetContainer, showSearchForm, gotoSearchResult, 
        searchUsers, deleteUsers, setCurrentUser, getUser, updateUser, createUser, deleteUser, 
        getKreditorListe, isAdminKreditor, copyKonserAdmin, toggleSelectedDelete, toggleAllSelectedDelete 
    } = useUserAdminReducer(messages[locale])

    const mockedData = mockData

    const [cases, setCases] = useState([])

    // klikk på menyen setter en prop med random verdi for å tvinge rerender av aktiv form
    useEffect(() => {
        //console.log('***RESET form***', JSON.stringify(props))
        resetContainer();
    }, [props]);

    // Holder styr på om søk har blitt gjort. Om første søk animerers søkeresultatene inn, om søk allerede er utført skal ikke animasjon skje på nytt
    const [firstSearch, setFirstSearch] = useState(true);
    const [isSearchPerformed, setIsSearchPerformed] = useState(false);
 
    return (

        <IntlProvider locale={locale} messages={messages[locale]}>

            {state.error && <Alert variant='danger'>{state.error}</Alert>}

            {state.savedMessage && <Alert variant='info'>{state.savedMessage}</Alert>}
            {(state.activeForm == displayForms.SEARCHFORM || state.activeForm == displayForms.SEARCHRESULT) &&
                <SearchContainer>
                <SearchSection fullWidth={!isSearchPerformed}>
                    <SearchComponent
                        searchUsers={searchUsers}
                        createUser={createUser}
                        isAdminKreditor={isAdminKreditor}
                        copyKonserAdmin={copyKonserAdmin}
                        state={state}
                        firstSearch={firstSearch}
                        isSearchPerformed={isSearchPerformed}
                        onSearch={() => {
                            if (firstSearch) setFirstSearch(false);
                            setIsSearchPerformed(true);
                        }}
                    />
                </SearchSection>
                 <ResultSection isVisible={isSearchPerformed} animate={firstSearch}>
                    <SearchResultComponent
                        setCurrentUser={setCurrentUser}
                        users={state.users}
                        loading={state.loading}
                        showSearchForm={showSearchForm}
                        toggleSelectedDelete={toggleSelectedDelete}
                        toggleAllSelectedDelete={toggleAllSelectedDelete}
                        deleteUsers={deleteUsers}
                    />
                </ResultSection>
            </SearchContainer>
            }

            {(state.activeForm == displayForms.EDITFORM) &&
                <EditProfile
                    formValues={state.user}
                    foreningkreditorListe={state.currentForeningKreditorListe}
                    updateUser={updateUser}
                    createUser={createUser}
                    deleteUser={deleteUser}                    
                    getKreditorListe={getKreditorListe}
                    gotoSearchResult={gotoSearchResult}
                    loading={state.loading}
                    state={state}
                />    
            }

            {/*
            {JSON.stringify(props)}
            {state.activeForm}
            {JSON.stringify()}
             */} 

        </IntlProvider>
    )
}

export default UserAdminContainer