import React, { useState, useEffect } from 'react';
import { IntlProvider, FormattedMessage, FormattedDate } from 'react-intl'
import LocaleContext from '../../../state/contexts/LocaleContext'
import { messages } from './SearchResultComponent.i18n'

import { ArticleArea } from '../../../Styling/Layout/ContentArea'
import { Container, Col, OverlayTrigger, Tooltip, Badge } from "react-bootstrap";
import { BfRowForm, BfButtonLink, BfButtonBlue, BfCheckboxContainer, BfCheckboxInput } from '../../../Styling/styledForm/styledBlueForm'

import UseTableSorter from '../../../components/useTable/UseTable'
import usePaginator from '../../../components/useTable/UsePaginator'
import { StyledKfTable } from './../../../Styling/styledTable/StyledTable';
import { Spinner } from '../../../components/spinner/Spinner'
import { useSearchResultModal } from '../modals/useSearchResultModal'
import SearchResultModal from '../modals/SearchResultModal';
import { useAuth } from '../../../hooks/useAuth'

//import styled from 'styled-components'

const LockoutTooltip = (props) => {
    return (
        <OverlayTrigger
            id={'ot' + props.i}
            key={'kt' + props.i}
            placement='top'
            overlay={<Tooltip key={'tkt' + props.i} id={props.i}>{props.caption}              
            </Tooltip>}
        >
            {props.children}
        </OverlayTrigger>
    )
}

const SearchResultComponent = (props) => {

    const locale = React.useContext(LocaleContext);
    const { header, beskrivelse, SearchFieldPlaceholder, SearcValuePlaceholder } = messages[locale]
    const localeMessages = messages[locale]
    const hasResults = Array.isArray(props.users) || props.users.length
    const auth = useAuth()
    const loggedInUser = auth.user

    // State for grid sorting
    const [currentSeq, setCurrentSeq] = useState('default'); // default
    const [currentField, setCurrentField] = useState('userName');    // feltnavn
    const [currentType, setCurrentType] = useState('string'); // num
    //const [sortClicked, setSortClicked] = useState(0)

    // state for grid paginator
    const [numberPrPage, setNumberPrPAge] = useState(10)
    const [activePage, setActivePage] = useState(1);

    //const mockedData = mockData
    const casesFilteredByStatus = props.users
    const [t1ChangeSortField, t1GetSortFunc, t1IndicaterIcon] = UseTableSorter(
        currentSeq, setCurrentSeq, 
        currentField, setCurrentField, 
        currentType, setCurrentType
    )
    const [Paginate, PaginationNav, PaginationDropdown, PaginationIndex, resetPagination] = usePaginator(
        casesFilteredByStatus.sort(t1GetSortFunc().fn),
        numberPrPage, setNumberPrPAge,
        activePage, setActivePage
    )

    const [users, setUsers] = useState(props.users);
    const [selectedRows, setSelectedRows] = useState([]);

    const handleChange = (selectedRow, i) => {
        props.toggleSelectedDelete(i, !selectedRow.isSelected);
        
        setUsers((prevUsers) =>
            prevUsers.map((user) =>
                user.i === selectedRow.i
                    ? { ...user, isSelected: user.userName !== selectedRow.userName && !selectedRow.isSelected }
                    : user
            )
        );
    };
    
    const handleChangeAll = (selectedValue) => {
        props.toggleAllSelectedDelete(selectedValue);
        
        setUsers((prevUsers) =>
            prevUsers.map((user) => ({
                ...user,
                isSelected: user.userName !== loggedInUser.username && selectedValue
            }))
        );
    };
    
    useEffect(() => {
        const updatedSelectedRows = props.users.filter(
            (user) => user.isSelected && user.userName !== loggedInUser.username
        );
        setSelectedRows(updatedSelectedRows);
    }, [users]);

    const deleteSelected = () => {
       props.deleteUsers(selectedRows);
    }

    const deleteModal = useSearchResultModal(localeMessages, deleteSelected, selectedRows)


    /*
    -UserName
    -Fullname
    -Forening
    -KrRolle
    CustomerNo
    -KreditorName
    */

    // Styrer sortering
    const _Email = 'userName'
    const _Navn = 'fullName'
    const _PhoneNumber = 'phoneNumber'
    const _Kreditor = 'kreditorName'
    const _Rolle = 'krRolle'
    const _Forening = 'foreningsNavn'
    const _CustomerNo = 'customerNo'
    const _OrgNo = 'orgNo'
    const _Disabled = 'lockSort'
    const _LastLogin = 'lastLogin'

    // Styrer header i18n
    const _HEmail = 'Epostadresse'
    const _HNavn = 'Navn'
    const _HPhoneNumber = 'PhoneNumber'
    const _HKreditor = 'Kreditorer'
    const _HRolle = 'Tilgangsnivå'
    const _HForening = 'Forening'
    const _HCustomerNo = 'CustomerNo'
    const _HOrgNo = 'OrgNo'
    const _HDisabled = 'Disabled'
    const _HLastLogin = 'LastLogin'
    const _HToggleDelete = 'Delete'

    const getForeningsNavn = (id) => {
        if (id === 20)
            return 'Kreditorforeningen SA'          
        else
            return ''
    }

    const getRolle = (row) => {
        var result = localeMessages[row.krRolle]

        if (row.webserviceBruker) {
            result = `WS${result}`
        }

        return result
    }

    if (props.loading) {
        return (
            <div style={{ marginTop: '300px', textAlign: 'center' }}>
                <Spinner />
            </div>
        );
    }    

    const isDisabled = disabled => {
        if (disabled === true) 
            return 'Ja'
        return  ''
    }

    const showLastLogin = (lastLogin) => {
        if (lastLogin) {
            return (
                <FormattedDate value={lastLogin} day="2-digit" month="2-digit" year="numeric" />
            )
        }
        return ''
    }

    const showLockoutInfo = (row, i) => {

        if (row.lockoutEnd) {
            return (
                <LockoutTooltip
                    id={i}
                    caption={localeMessages['Locked']}
                    locked={row.lockoutEnd}
                >
                    <span style={{ color: 'red' }} className="fa fa-lock" />
                </LockoutTooltip>
            )
        }

        if (row.accessFailedCount) {
            return (
                <LockoutTooltip
                    id={i}
                    caption={localeMessages['AccessFailedCounts']}
                    count={row.accessFailedCount}
                >
                    <Badge pill variant="warning">
                        {row.accessFailedCount}
                    </Badge>

                </LockoutTooltip>
            )
        }

        return (null)

    }

    return (
        <IntlProvider locale={locale} messages={messages[locale]}>

            {deleteModal.dialogVisible && <SearchResultModal deleteModal={deleteModal} />}

            <ArticleArea>
                <h1>{<FormattedMessage id="header" />}</h1>
                <p>{<FormattedMessage id={hasResults ? "beskrivelseEtterSøk" : 'beskrivelse'} />}</p>
                <br />
            </ArticleArea>

            <Container fluid="true">

                <BfRowForm>

                    <Col xs={12} md={12}>

                        <PaginationDropdown />
                        <StyledKfTable>
                            <thead>
                                <tr>
                                    <th onClick={() => t1ChangeSortField(_Email, 'string')}><FormattedMessage id={_HEmail} />{t1IndicaterIcon(_Email)}</th>
                                    <th onClick={() => t1ChangeSortField(_Navn, 'string')}><FormattedMessage id={_HNavn} />{t1IndicaterIcon(_Navn)}</th>
                                    <th onClick={() => t1ChangeSortField(_PhoneNumber, 'string')}><FormattedMessage id={_HPhoneNumber} />{t1IndicaterIcon(_PhoneNumber)}</th>
                                    <th onClick={() => t1ChangeSortField(_Rolle, 'string')}><FormattedMessage id={_HRolle} />{t1IndicaterIcon(_Rolle)}</th>
                                    {/*<th onClick={() => t1ChangeSortField(_Forening, 'string')}><FormattedMessage id={_HForening} />{t1IndicaterIcon(_Forening)}</th>*/}
                                    {/* <th onClick={() => t1ChangeSortField(_CustomerNo, 'string')}><FormattedMessage id={_HCustomerNo} />{t1IndicaterIcon(_CustomerNo)}</th> */}
                                    <th onClick={() => t1ChangeSortField(_Kreditor, 'string')}><FormattedMessage id={_HKreditor} />{t1IndicaterIcon(_Kreditor)}</th>
                                    {/* <th onClick={() => t1ChangeSortField(_OrgNo, 'string')}><FormattedMessage id={_HOrgNo} />{t1IndicaterIcon(_OrgNo)}</th> */}
                                    <th onClick={() => t1ChangeSortField(_Disabled, 'num')}><FormattedMessage id={_HDisabled} />{t1IndicaterIcon(_Disabled)}</th>
                                    <th onClick={() => t1ChangeSortField(_LastLogin, 'datetime')}><FormattedMessage id={_HLastLogin} />{t1IndicaterIcon(_LastLogin)}</th>
                                    <th>
                                        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                            <FormattedMessage id={_HToggleDelete} />
                                            <BfCheckboxContainer>
                                                <BfCheckboxInput
                                                    onChange={(e) => handleChangeAll(e.target.checked)}
                                                    disabled={false}
                                                />
                                                <span className="tooltip-text">Velg alle</span>
                                            </BfCheckboxContainer>
                                        </div>
                                    </th>
                                </tr>
                            </thead>

                            <tbody>

                                <Paginate>
                                    {(row, i) =>
                                        <tr key={i}>
                                            <td><BfButtonLink variant="link" onClick={() => props.setCurrentUser(row.userName)}>{row.userName}</BfButtonLink></td>
                                            <td>{row.fullName}</td> 
                                            <td>{row.phoneNumber}</td>
                                            <td>{localeMessages[row.krRolle]}</td>
                                            {/* <td>{row.forening}</td> */}
                                            {/*  <td>{row.customerNo}</td> */}
                                            <td>{row.kreditorName}</td> 
                                            {/* <td>{row.orgNo}</td> */}
                                            <td>
                                                {isDisabled(row.disabled) || showLockoutInfo(row, i)}                                               
                                            </td>
                                            <td>{showLastLogin(row.lastLogin)}</td>
                                            <td>
                                                {row.userName!== loggedInUser.username &&
                                                (<BfCheckboxContainer>
                                                    <BfCheckboxInput
                                                        checked={row.isSelected}
                                                        onChange={() => handleChange(row, i)}
                                                        disabled={false}
                                                    />
                                                    <span className="tooltip-text"> {row.isSelected ? 'Valgt for sletting' : "Velg for sletting"}</span>
                                                </BfCheckboxContainer>)}
                                            </td>
                                            
                                        </tr>
                                    }
                                </Paginate>

                            </tbody>

                        </StyledKfTable>
                        <br />
                        <PaginationNav /><PaginationIndex />
                        &nbsp;&nbsp;&nbsp;
                        <BfButtonBlue variant="primary" type="button" onClick={() => deleteModal.showDialog()} disabled={selectedRows.length === 0}>
                            <FormattedMessage id="Slett" />
                        </BfButtonBlue>
                        <br /><br />
                        

                    </Col>

                </BfRowForm>

                <br />
              


            </Container>


        </IntlProvider>
    )
}

export default SearchResultComponent