import React, { useState, useContext, useEffect } from 'react';
import { IntlProvider, FormattedDate, FormattedNumber } from 'react-intl'
import { NavLink } from 'react-router-dom';
import { StyledKfTextTable, StyledNavLink, StyledPropotionalNavLink, StyledExpandRowTextTable } from './../../../Styling/styledTable/StyledTable';
import { BfRowForm, BfButtonBlue, BfButtonBlueToggle, BfCheckboxContainer, BfCheckboxInput, BfCheckboxLabel, LesButton, SvarButton, ContainerButton, ButtonGroup } from '../../../Styling/styledForm/styledBlueForm'
import UseTableSorter from '../../../components/useTable/UseTable'
import usePaginator from '../../../components/useTable/UsePaginator'
import OversiktContext from '../OversiktContext';
import CaseListToolTip from '../../caselist_redux/caseListTable/CaseListTooltip';
import FakturaListe from './FakturaListe';
import LocaleContext from '../../../state/contexts/LocaleContext';
import styled, { keyframes } from 'styled-components';
import { fixMissingTimeFromDate } from '../../../util/date/dateRoutines';
import colorConfig from '../../../colorConfig';

const breatheAnimation = keyframes`
        0% { transform: translateX(0) }
        25% { transform: translateX(4px) }
        50% { transform: translateX(-4px) }
        75% { transform: translateX(4px) }
        100% { transform: translateX(0) }
        `;

const StyledTh = styled.th`
        animation-name: ${breatheAnimation};
        animation-duration: 0.5s;
        animation-iteration-count: 1;
    `

const MeldingsListe = (props) => {

    const locale = useContext(LocaleContext)

    const { localeMessages, handleLesShow, handleSvarShow } = React.useContext(OversiktContext);

    const originalTableOrder = [
        'invoiceListLength',
        'regDate',
        'caseNo',
        'debtorName',
        'creditorNo',
        'creditorName',
        'caseReference',
        'messageDirection',
        'caseStatus',
        'commentIcon',
        'button',
    ]

    const [colOrder, setColOrder] = useState(originalTableOrder)
    const [isModified, setIsModified] = useState(false);


    const resetColOrder = () => {
        setColOrder(originalTableOrder)
        saveColSettings(originalTableOrder)
        setIsModified(false);
    }
    const [dragOver, setDragOver] = useState("");
    const [fromCol, setFromCol] = useState();

    const handleDragStart = e => {
        const { id } = e.target;
        setFromCol(id)
    };

    const handleDragOver = e => e.preventDefault();
    const handleDragEnter = e => {
        const { id } = e.target;
        setDragOver(id);
    };

    const delColByName = (names) => {
        const tempCols = [...colOrder];

        names.forEach(name => {
            let fromCol = tempCols.indexOf(name);
            if (fromCol !== -1) {
                tempCols.splice(fromCol, 1);
            }
        });

        setColOrder(tempCols);
        setDragOver("");
        setFromCol(null);
        saveColSettings(tempCols);
    };

    const hideSelectedColumns = () => {
        delColByName(selectedColumns);
        setSelectedColumns([]);
        setIsModified(true);
    };

    const array_move = (arr, old_index, new_index) => {
        if (new_index >= arr.length) {
            var k = new_index - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
        return arr;
    };


    const handleOnDrop = e => {
        const { id } = e.target;
        let tempCols = array_move(colOrder, fromCol, id)
        setColOrder(tempCols);
        saveColSettings(tempCols)
        setFromCol(null)
        setDragOver("");
        setIsModified(true);
    };

    const saveColSettings = (cols) => {
        localStorage.setItem("oppgaverCols", JSON.stringify(cols));
    }

    // Sjekker om originalTableOrder har blitt endret, enten ved å skjule eller endre posisjon på kolonne(r)
    // Slik at Tilbakestill kolonner knappen er synlig om bruker logger av og på
    const checkIfModified = (arr1, arr2) => {
        if (arr1.length !== arr2.length) return false;
        for (let i = 0; i < arr1.length; i++) {
            if (arr1[i] !== arr2[i]) return false;
        }
        return true;
    };

    useEffect(() => {
        var savedColorder = JSON.parse(localStorage.getItem("oppgaverCols"));
        if (savedColorder) {
            setColOrder(savedColorder)
            if (!checkIfModified(originalTableOrder, savedColorder)) {
                setIsModified(true);
            }
        }
    }, [])

    const [isEditing, setIsEditing] = useState(false);
    const [selectedColumns, setSelectedColumns] = useState([]);

    const toggleEditMode = () => {
        setIsEditing(!isEditing);
        setSelectedColumns([]);
        setFromCol(null)
    };

    const handleCheckboxChange = (colname) => {
        setSelectedColumns(prevState =>
            prevState.includes(colname) ? prevState.filter(col => col !== colname) : [...prevState, colname]
        );
    };

    const BfCheckbox = ({ label, value, onChange }) => {
        return (
            <BfCheckboxContainer>
                <BfCheckboxInput checked={value} onChange={onChange} />
                <BfCheckboxLabel>{label}</BfCheckboxLabel>
            </BfCheckboxContainer>
        )
    }

    const DelIcon = (props) => {
        const sortField = props.colname
        const { isEditing, selectedColumns, onCheckboxChange } = props;
        const isChecked = selectedColumns.includes(sortField);
        return (
            isEditing ? <BfCheckbox value={isChecked} onChange={onCheckboxChange} /> : null
        )
    }

    const ThExt = (props) => {
        const i = props.i
        const headerCaption = props.headerCaption
        const sortField = props.colName
        var sortType = props.sortType

        let textAlign = 'left'
        let paddingLeft = '8px'
        if (i.toString() === fromCol) {
            textAlign = 'center'
            paddingLeft = '0px'
        }
        else if (sortType === 'num' && sortField !== 'invoiceListLength') {
            textAlign = 'right'
            paddingLeft = '0px'
        }
            
        let bgColor = '#f5f2eb'
        if (i.toString() === fromCol)
            bgColor = '#C8AD7F'

        let _style = {
            textAlign: textAlign,
            backgroundColor: bgColor,
            borderRadius: '4px',
            paddingLeft: paddingLeft,
        }

        if (isEditing) {
            return (
                <StyledTh
                    id={i}
                    draggable
                    onClick={handleDragStart}
                    onDragStart={handleDragStart}
                    onDragOver={handleDragOver}
                    onDrop={handleOnDrop}
                    onDragEnter={handleDragEnter}
                    style={_style}
                >
                    <span className="fa fa-caret-left" />&nbsp;&nbsp;
                    {headerCaption}
                    {props.children}
                    &nbsp;&nbsp;<span className="fa fa-caret-right" />
                    &nbsp;
                    <DelIcon
                        key={sortField}
                        isEditing={isEditing}
                        colname={sortField}
                        selectedColumns={selectedColumns}
                        onCheckboxChange={() => handleCheckboxChange(sortField)}
                    />
                </StyledTh>
            );
        } else {
            return (
                <th
                    id={i}
                    onClick={sortType ? () => t1ChangeSortField(sortField, sortType) : undefined}
                    style={_style}
                >
                    {headerCaption}
                    {props.children}
                    {t1IndicaterIcon(sortField)}
                    &nbsp;
                    <DelIcon
                        key={sortField}
                        isEditing={isEditing}
                        colname={sortField}
                        selectedColumns={selectedColumns}
                        onCheckboxChange={() => handleCheckboxChange(sortField)}
                    />
                </th>
            );
        }
    }

    const PrintHeaderItem2 = props => {
        var colname = props.colName
        var i = props.i

        if (colname === 'invoiceListLength') return (
            <ThExt i={i} headerCaption={''} colName={colname} sortType={'num'} t1IndicaterIcon={t1IndicaterIcon} t1ChangeSortField={t1ChangeSortField} />
        )
        else if (colname === 'regDate') return (
            <ThExt i={i} headerCaption={localeMessages['GH_regDate']} colName={colname} sortType={'datetime'} t1IndicaterIcon={t1IndicaterIcon} t1ChangeSortField={t1ChangeSortField} />
        )
        else if (colname === 'caseNo') return (
            <ThExt i={i} headerCaption={localeMessages['GH_caseNo']} colName={colname} sortType={'string'} t1IndicaterIcon={t1IndicaterIcon} t1ChangeSortField={t1ChangeSortField} />
        )
        else if (colname === 'debtorName') return (
            <ThExt i={i} headerCaption={localeMessages['GH_debtorName']} colName={colname} sortType={'string'} t1IndicaterIcon={t1IndicaterIcon} t1ChangeSortField={t1ChangeSortField} />
        )
        else if (colname === 'creditorNo') return (
            <ThExt i={i} headerCaption={localeMessages['GH_creditorNo']} colName={colname} sortType={'string'} t1IndicaterIcon={t1IndicaterIcon} t1ChangeSortField={t1ChangeSortField} />
        )
        else if (colname === 'creditorName') return (
            <ThExt i={i} headerCaption={localeMessages['GH_creditorName']} colName={colname} sortType={'string'} t1IndicaterIcon={t1IndicaterIcon} t1ChangeSortField={t1ChangeSortField} />
        )
        else if (colname === 'caseReference') return (
            <ThExt i={i} headerCaption={localeMessages['GH_caseReference']} colName={colname} sortType={'string'} t1IndicaterIcon={t1IndicaterIcon} t1ChangeSortField={t1ChangeSortField} />
        )
        else if (colname === 'messageDirection') return (
            <ThExt i={i} headerCaption={localeMessages['GH_messageDirection']} colName={colname} sortType={'string'} t1IndicaterIcon={t1IndicaterIcon} t1ChangeSortField={t1ChangeSortField} />
        )
        else if (colname === 'caseStatus') return (
            <ThExt i={i} headerCaption={localeMessages['GH_caseStatus']} colName={colname} sortType={'string'} t1IndicaterIcon={t1IndicaterIcon} t1ChangeSortField={t1ChangeSortField} />
        )
        else if (colname === 'commentIcon') return (
            <ThExt i={i} headerCaption={''} colName={colname} />
        )
        else if (colname === 'button') return (
            <ThExt i={i} headerCaption={''} colName={colname} />
        )
        else {
            return <></>
        }
    }

    const printHeader = () => {
        return (
            <thead>
                <tr>
                    {colOrder.map((colName, i) =>
                        <PrintHeaderItem2
                            colName={colName}
                            t1ChangeSortField={t1ChangeSortField}
                            t1IndicaterIcon={t1IndicaterIcon}
                            i={i}
                        />)
                    }
                </tr>
            </thead>
        )
    }

    const [expandedRows, setExpandedRows] = useState({});
    const toggleExpand = (caseNo) => {
        setExpandedRows((prevExpandedRows) => ({
            ...prevExpandedRows,
            [caseNo]: !prevExpandedRows[caseNo],
        }));
    };

    const PrintDetailItem = (model) => {
        var colname = model.colName
        var dataModel = model.dataModel
        var caseDetUri = model.caseDetUri

        const asDate = (val) => {
            if (!val)
                return (<></>)

            // Kludge fordi FireFox ikke godtar dato uten tid uten videre
            let valok = fixMissingTimeFromDate(val)
            //let valok = val
            return (
                <>
                    <FormattedDate value={valok} day="2-digit" month="2-digit" year="numeric" />
                </>
            )
        }

        const invoiceButton = () => {
            if (props.expand) {
                return (
                    <span onClick={() => toggleExpand(dataModel.caseNo)}>
                        {expandedRows[dataModel.caseNo] ? (
                            <span className="fa fa-search-minus" />
                        ) : (
                            <span className="fa fa-search-plus" />
                        )}
                        <>&nbsp;&nbsp;({dataModel.invoiceList.length})</>
                    </span>
                );
            }
        }

        if (colname === 'invoiceListLength') return (
            <td> 
                {invoiceButton()} 
            </td>
        )
        else if (colname === 'regDate') return (
            <td> 
                {asDate(dataModel.regDate)} 
            </td>
        )
        else if (colname === 'caseNo') return (
            <td>
                <StyledPropotionalNavLink to={caseDetUri}>
                    {dataModel.caseNo}
                </StyledPropotionalNavLink>
                &nbsp;&nbsp;
            </td>
        )
        else if (colname === 'debtorName') return (<td>{dataModel.debtorName}</td>)
        else if (colname === 'creditorNo') return (<td>{dataModel.creditorNo}</td>)
        else if (colname === 'creditorName') return (<td>{dataModel.creditorName}</td>)
        else if (colname === 'caseReference') return (<td>{dataModel.caseReference}</td>)
        else if (colname === 'messageDirection') return (<td>{dataModel.messageDirection}</td>)
        else if (colname === 'caseStatus') return (<td>{dataModel.caseStatus}</td>)
        else if (colname === 'commentIcon') return (  <td>
            {dataModel.lastCaseNote &&
                <CaseListToolTip id={'ctn' + dataModel.i} caption={dataModel.lastCaseNote}>
                    <span className="fa fa-commenting" />
                </CaseListToolTip>
            }
            {!dataModel.lastCaseNote &&                        
                <span className="fa fa-commenting" style={{ color: "#a4a4a4"}}/>
            }
        </td>
        )
        else if (colname === 'button') return ( <td>
            {dataModel.isHistorical &&
                <>
                    <LesButton onClick={() => handleLesShow(dataModel)}>
                        Les
                    </LesButton>
                </>
            }
            {!dataModel.isHistorical && dataModel.isReplyable &&
                <>
                    <SvarButton onClick={() => handleSvarShow(dataModel)}>
                        Svar
                    </SvarButton>
                </>
            }
            {!dataModel.isHistorical && dataModel.isInformation &&
                <>
                    <LesButton onClick={() => handleLesShow(dataModel)}>
                        Les
                    </LesButton>
                </>
            }                                                      
            &nbsp;
        </td>
        )
        else
            return (
                <></>
        )
       
       
    }

    const printRow = (dataModel) => {
        let caseDetUri = '/CaseDetails/' + dataModel.creditorNo + '/' + dataModel.caseNo
        const isExpanded = expandedRows[dataModel.caseNo];
        return (
            <>
                <tr key={dataModel.caseNo} onClick={() => { setFromCol("") }}>
                    {colOrder.map((colName, i) =>
                        <PrintDetailItem
                            colName={colName}
                            dataModel={dataModel}
                            caseDetUri={caseDetUri}
                            key={i}
                        />)
                    }
                </tr>
                {isExpanded && (
                    <>
                        <tr key={dataModel.i + 1000} align='right' style={{ backgroundColor: 'white', hover: { opacity: 1 }}} >
                            <td></td>
                            <td colspan="11" className={isExpanded ? 'expand-animation' : 'minimize-animation'}>
                                <FakturaListe tableData={dataModel.invoiceList} />
                            </td>
                        </tr>
                        <tr style={{ backgroundColor: 'white' }}>
                            <td colspan="11"></td>
                        </tr>
                    </>
                )}
            </>
        )
    }

    // State for grid sorting
    const [currentSeq, setCurrentSeq] = useState('down'); // default
    const [currentField, setCurrentField] = useState('regDate');    // feltnavn
    const [currentType, setCurrentType] = useState('datetime'); // num

    // state for grid paginator
    const [numberPrPage, setNumberPrPAge] = useState(10)
    const [activePage, setActivePage] = useState(1);

    // Point to data 
    const tableData = props.tableData

    const [t1ChangeSortField, t1GetSortFunc, t1IndicaterIcon] = UseTableSorter(
        currentSeq, setCurrentSeq,
        currentField, setCurrentField,
        currentType, setCurrentType
    )

    // Setupt paginator
    const [Paginate, PaginationNav, PaginationDropdown, PaginationIndex, resetPagination] = usePaginator(
        tableData.sort(t1GetSortFunc().fn), // <==== sorting inputdata =========
        numberPrPage, setNumberPrPAge,
        activePage, setActivePage
    )

    return (
        <IntlProvider locale={locale} messages={localeMessages}>
    
        <ContainerButton>
            {tableData.length !== 0 && <PaginationDropdown />}
      
            <ButtonGroup>
            {isModified && (
                <BfButtonBlue variant="primary" type="button" onClick={resetColOrder}>
                    {localeMessages['resetColumns']}
                </BfButtonBlue>
            )}

            {selectedColumns.length > 0 && (
                <BfButtonBlue variant="primary" type="button" onClick={hideSelectedColumns}>
                    {localeMessages['hideColumns']}
                </BfButtonBlue>
            )}
                <BfButtonBlueToggle variant="primary" type="button" onClick={toggleEditMode} isActive={isEditing}>
                    {isEditing ? localeMessages['stopEditing'] : localeMessages['editColumns']}
                </BfButtonBlueToggle>
            </ButtonGroup>
        </ContainerButton>
            <StyledExpandRowTextTable>
                {printHeader()}

                <tbody>
                    <Paginate>
                        {/* paginate forventer en funksjon */}
                        {row => printRow(row)}
                    </Paginate>
                </tbody>
            </StyledExpandRowTextTable>
            {props.tableData.length !== 0 &&
                <>
                    <br />
                    <PaginationIndex />
                    <br /><br />
                    <PaginationNav />
                </>
            }
        
        </IntlProvider>        
    )
}

export default MeldingsListe
