import { toast } from 'react-toastify';
import _ from 'lodash'
import axiosTokenInstance from '../../Instances/axiosTokenInstance';
import { dateToString, dateToStringDMY } from '../../util/date/dateRoutines';
import { numberToLocaleString } from '../../util/numbers/numberRoutines';


/*
To use:
   import { useSelector, useDispatch } from 'react-redux'
   import { ..exported dispatchers... } from '../../reduxStore/...';

   // Angi ønsket redux store du vil hente state fra
   const state = useSelector(state => state.oversikt)

   // Få tak i redux dispatheren som du evt kan gi eget navn
   const _dispatch = useDispatch()
    ..

   // Kjør kall for å endre state
   _dispatch(setSomeShit(shitparam1))

*/

export const actions = {
  FAKTURA_ADM_LOADING: 'FAKTADM_LOADING',
  FAKTURA_ADM_CLEAR_LOADING: 'FAKTURA_ADM_CLEAR_LOADING',
  FAKTURA_ADM_ERROR: 'FAKTURA_ADM_ERROR',
  FAKTURA_ADM_DATA_FETCHED: 'FAKTURA_ADM_DATA_FETCHED',
  FAKTURA_ADM_DATA_CLEAR: 'FAKTURA_ADM_DATA_CLEAR',
  FAKTURA_ADM_TOGGLE_SELECTED: 'FAKTURA_ADM_TOGGLE_SELECTED',
  FAKTURA_ADM_TOGGLE_ALL: 'FAKTURA_ADM_TOGGLE_ALL',
  FAKTURA_ADM_TOGGLE_PASSED_DUE_DATE: 'FAKTURA_ADM_TOGGLE_PASSED_DUE_DATE',
  FAKTURA_ADM_DISABLE_SELECTED: 'FAKTURA_ADM_DISABLE_SELECTED',
  FAKTURA_ADM_SET_KISINTEGRATION: 'FAKTURA_ADM_SET_KISINTEGRATION',
  FAKTURA_ADM_SET_APITIMESTAMP: 'FAKTURA_ADM_SET_APITIMESTAMP',
  FAKTURA_ADM_SET_SOKEVERDI: 'FAKTURA_ADM_SET_SOKEVERDI'
}

const getErrorMessage = (err) => {
    if (_.has(err, 'response.data.title')) {
        return err.response.data.title
    }
    else if (_.has(err, 'response.data')) {
      return err.response.data
    }
    else if (_.has(err, 'response.statusText')) {
        return err.response.statusText    
    } else {
        return err.message.toString()
    }
}

/*

"klist": {
    "invoiceList": [
      {
        "invoiceNumber": "string",
        "customerReference": "string",
        "invoiceAmount": 0,
        "invoiceDate": "string",
        "dueDate": "string",
        "kid": "string",
        "currencyCode": "string",
        "invoiceText": "string",
        "debitor": {
          "debitorId": "string",
          "debitorName": "string",
          "debitorCode": "string",
          "email": "string",
          "cellPhone": "string",
          "customerType": "string",
          "address": {
            "streetAddress": "string",
            "city": "string",
            "zipCode": "string",
            "countryCode": "string",
            "streetAddressList": [
              "string"
            ]
          }
        }
      }
    ]
  },

  */

const mapDataToList = (invoiceList) => {

  const uCase = (e, fieldName) => {
    if (e.hasOwnProperty(fieldName) ) {
      let val = e[fieldName]
      if (val) {
        //console.log(fieldName,  val.toUpperCase())
        return val.toUpperCase()
      }     
    }
      
    //console.log(fieldName, "not found")
    return ''
  }

  const isMoreThan14Days = (date) => {
    const currentDate = new Date();
    const inputDate = new Date(date);
  
    const differenceInTime = inputDate.getTime() - currentDate.getTime();
    const differenceInDays = differenceInTime / (1000 * 3600 * 24);
  
    return differenceInDays <= -15;
  };

  return invoiceList.map((e, i) => {
    const item = {
      i: i,
      isSelected: false,
      disabled: '',
      invoiceDate: e.invoiceDate,
      dueDate: e.dueDate,
      debitorId: e.customerReference,  //e.debitor.debitorId,
      debitorName: e.debitor.debitorName,
      invoiceNumber: e.invoiceNumber,
      invoiceText: e.invoiceText,
      invoiceAmount: e.invoiceAmount,
      product: e.product,
      passedDueDate: isMoreThan14Days(e.dueDate),
      searchIndex: `${dateToStringDMY(e.invoiceDate)};${dateToStringDMY(e.dueDate)};${uCase(e, "product")};${e.customerReference};${uCase(e.debitor, "debitorName")};${e.invoiceNumber};${uCase(e, "invoiceText")};${numberToLocaleString(e.invoiceAmount)}`
    }
    //console.log(item.searchIndex)
    return item
  })
}

export const clearData = () => {
  return dispatch => {    
      dispatch({ type: actions.FAKTURA_ADM_DATA_CLEAR, payload: null })
  }
}

export const toggleSelected = (i, newVal) => {
    return dispatch => {
        dispatch({ type: actions.FAKTURA_ADM_TOGGLE_SELECTED, payload: {i: i, value: newVal} })
    }
}

export const toggleAll = (newVal) => {
    return dispatch => {
        dispatch({ type: actions.FAKTURA_ADM_TOGGLE_ALL, payload: {value: newVal} })
    }
}

export const togglePassedDueDate = (newVal) => {
  return dispatch => {
      dispatch({ type: actions.FAKTURA_ADM_TOGGLE_PASSED_DUE_DATE, payload: {value: newVal} })
  }
}

export const getFakturaAdmin = (creditorNo, months, showToasts, messages) => {

    return dispatch => {

        getFakturaAdminDispatcher(dispatch, creditorNo, months, showToasts, messages)
    }
}


export const setApiTimestamp = (apiTimeSTamp) => {
  return dispatch => {
      dispatch({ type: actions.FAKTURA_ADM_SET_APITIMESTAMP, payload: {value: apiTimeSTamp} })
  }
}

export const setFakturaFilterString = (filterString) => {
  return dispatch => {
      dispatch({ type: actions.FAKTURA_ADM_SET_SOKEVERDI, payload: {value: filterString }})
  }
}


const getFakturaAdminDispatcher = (dispatch, creditorNo, months, showToasts, messages) => {

    const errorMessage = messages['toastHentFakturalisteFailed']
    const okMessage = messages['toastHentFakturalisteOk']

    dispatch({ type: actions.FAKTURA_ADM_LOADING, data: null })
    dispatch({ type: actions.FAKTURA_ADM_SET_APITIMESTAMP, payload: {value: ''} })

    axiosTokenInstance
        ({
            method: 'GET',
            url: `/api/fakturaAdmin/getKisInvoiceList/${creditorNo}/${months}`,
        })
        .then((result) => {
            //console.log(result)

            if (result.status !== 200) {

                (showToasts && toast.error(errorMessage, {autoClose: false}))
                dispatch({ type: actions.FAKTURA_ADM_ERROR, data: errorMessage })

            } else {

              const data = result.data
              if (data.errorCode === "000") {
                
                  (showToasts && toast.info(okMessage))
                  dispatch({ type: actions.FAKTURA_ADM_SET_KISINTEGRATION, payload: { kisIntegration: data.kisIntegration}})
                  
                  if (data.klist) {                    
                    dispatch({ type: actions.FAKTURA_ADM_DATA_FETCHED, payload: { invoiceList: mapDataToList(data.klist.invoiceList), fetchedData: data.klist.invoiceList }})
                    // Dispatch apiTimeStamp
                    if (data.klist.invoiceList.length > 0)
                      dispatch({ type: actions.FAKTURA_ADM_SET_APITIMESTAMP, payload: {value: data.klist.invoiceList[0].apiTimeStamp} })                    
                  } else {
                    dispatch({ type: actions.FAKTURA_ADM_DATA_FETCHED, payload: { invoiceList: [], fetchedData:[] }})
                  }

                  //dispatch({ type: actions.FAKTURA_ADM_DATA_FETCHED, payload: { invoiceList: [...MockData], fetchedData: MockData}})
                  //dispatch({ type: actions.FAKTURA_ADM_TOGGLE_ALL, payload: { value: false}})
                } else {
                    (showToasts && toast.warning(errorMessage + ', ' + result.data.errorMessage))
                    dispatch({ type: actions.FAKTURA_ADM_ERROR, data: errorMessage + ', ' + result.data.errorMessage })
                }
            }
        })
        .catch((err) => {
            let lErrMess = errorMessage + ' ' + getErrorMessage(err)
            toast.error(lErrMess, {autoClose: false})
            dispatch({ type: actions.FAKTURA_ADM_ERROR, data: lErrMess })
        })
}


export const sendInnValgteFakturaer = (formData, showToasts, messages) => {

  return dispatch => {
    sendInnValgteFakturaerDispatcher(dispatch, formData, showToasts, messages)
  }
}


/*
   Merk at loading er fjernet for å få en fire og forget løsning.
  Fakturaer kan dermed sendes inn flere ganger
*/
const sendInnValgteFakturaerDispatcher = (dispatch, formData, showToasts, messages) => {

  const errorMessage = messages['toastSendFakturalisteFailed']
  const okMessage = messages['toastSendFakturalisteOk']
  const sendtMessage = messages['toastSendFakturaliste']

  dispatch({ type: actions.FAKTURA_ADM_LOADING, data: null })

  axiosTokenInstance
      ({
          method: 'POST',
          url: `/api/fakturaAdmin/PostKisInvoiceList/`,
          data: formData
      })
      .then((result) => {
          //console.log(result)
        
          dispatch({ type: actions.FAKTURA_ADM_CLEAR_LOADING, data: null })
          dispatch({ type: actions.FAKTURA_ADM_DISABLE_SELECTED, payload: {value: false} })

          if (result.status !== 200) {
              (showToasts && toast.error(errorMessage, {autoClose: false}))                        
          } else {

            if (result.data.errorCode === "000") {
              (showToasts && toast.info(sendtMessage))                                        
            } else {
              (showToasts && toast.error(errorMessage + ', ' + + ', ' + result.data.errorMessage, {autoClose: false}))
            }
          }        
      })
    .catch((err) => {
        dispatch({ type: actions.FAKTURA_ADM_CLEAR_LOADING, data: null })      
        let lErrMess = errorMessage + ' ' + getErrorMessage(err)
        toast.error(lErrMess)          
    })
    
}


const MockData = [{
    "i": 1,
    "isSelected": false,
    "invoiceDate": "2021-02-02T17:29:04Z",
    "dueDate": "2021-11-03T22:15:33Z",
    "debitorId": "645342",
    "invoiceNumber": "240711",
    "invoiceText": "Conan the Destroyer",
    "invoiceAmount": 12569.77,
    "debitorName": "Muller-Medhurst"
  }, {
    "i": 2,
    "isSelected": false,
    "invoiceDate": "2021-08-24T19:37:42Z",
    "dueDate": "2021-11-13T17:04:39Z",
    "debitorId": "125885",
    "invoiceNumber": "801822",
    "invoiceText": "Portrait Werner Herzog",
    "invoiceAmount": 365551.08,
    "debitorName": "Jaskolski Inc"
  }, {
    "i": 3,
    "isSelected": false,
    "invoiceDate": "2021-04-13T11:18:19Z",
    "dueDate": "2021-10-26T10:35:51Z",
    "debitorId": "468494",
    "invoiceNumber": "701283",
    "invoiceText": "Girl with Hyacinths",
    "invoiceAmount": 52002.26,
    "debitorName": "Graham, Deckow and Witting"
  }, {
    "i": 4,
    "isSelected": false,
    "invoiceDate": "2021-10-04T10:51:22Z",
    "dueDate": "2021-02-01T04:06:03Z",
    "debitorId": "519184",
    "invoiceNumber": "686745",
    "invoiceText": "Norte, the End of History",
    "invoiceAmount": 4100.39,
    "debitorName": "Kshlerin Inc"
  }, {
    "i": 5,
    "isSelected": false,
    "invoiceDate": "2021-08-01T13:03:56Z",
    "dueDate": "2021-01-24T14:38:17Z",
    "debitorId": "001283",
    "invoiceNumber": "577369",
    "invoiceText": "Morgan!",
    "invoiceAmount": 97.03,
    "debitorName": "Osinski, Kris and Upton"
  }, {
    "i": 6,
    "isSelected": false,
    "invoiceDate": "2021-08-18T08:58:50Z",
    "dueDate": "2021-06-18T05:44:37Z",
    "debitorId": "045588",
    "invoiceNumber": "345796",
    "invoiceText": "Monkey's Paw, The",
    "invoiceAmount": 91.83,
    "debitorName": "Rowe LLC"
  }, {
    "i": 7,
    "isSelected": false,
    "invoiceDate": "2021-03-16T11:57:27Z",
    "dueDate": "2021-10-06T19:08:10Z",
    "debitorId": "503349",
    "invoiceNumber": "622591",
    "invoiceText": "The Interview",
    "invoiceAmount": 57.24,
    "debitorName": "Buckridge, Kuhlman and Nicolas"
  }, {
    "i": 8,
    "isSelected": false,
    "invoiceDate": "2021-01-21T23:59:33Z",
    "dueDate": "2021-02-26T00:14:22Z",
    "debitorId": "250227",
    "invoiceNumber": "934103",
    "invoiceText": "Iceman Tapes: Conversations with a Killer, The",
    "invoiceAmount": 21.31,
    "debitorName": "Legros-Leannon"
  }, {
    "i": 9,
    "isSelected": false,
    "invoiceDate": "2021-03-28T07:41:14Z",
    "dueDate": "2021-01-10T12:15:56Z",
    "debitorId": "660712",
    "invoiceNumber": "353827",
    "invoiceText": "Killer Elite",
    "invoiceAmount": 54.22,
    "debitorName": "Hintz-Beer"
  }, {
    "i": 10,
    "isSelected": false,
    "invoiceDate": "2021-07-29T00:56:09Z",
    "dueDate": "2021-06-20T13:16:59Z",
    "debitorId": "226955",
    "invoiceNumber": "287038",
    "invoiceText": "Jim Thorpe -- All-American",
    "invoiceAmount": 10.67,
    "debitorName": "Reinger-Gibson"
  }, {
    "i": 11,
    "isSelected": false,
    "invoiceDate": "2021-06-15T22:37:04Z",
    "dueDate": "2021-05-06T07:54:05Z",
    "debitorId": "837184",
    "invoiceNumber": "300377",
    "invoiceText": "Wizard of Oz, The",
    "invoiceAmount": 57.15,
    "debitorName": "Emard-Dickens"
  }, {
    "i": 12,
    "isSelected": false,
    "invoiceDate": "2021-07-07T00:32:19Z",
    "dueDate": "2021-11-08T05:38:41Z",
    "debitorId": "596360",
    "invoiceNumber": "885463",
    "invoiceText": "Forbidden",
    "invoiceAmount": 41.1,
    "debitorName": "McCullough, Hoppe and Marvin"
  }, {
    "i": 13,
    "isSelected": false,
    "invoiceDate": "2021-02-06T14:11:12Z",
    "dueDate": "2021-09-09T13:43:12Z",
    "debitorId": "524512",
    "invoiceNumber": "520079",
    "invoiceText": "We Are The Night (Wir sind die Nacht)",
    "invoiceAmount": 57.16,
    "debitorName": "Bahringer-Bailey"
  }, {
    "i": 14,
    "isSelected": false,
    "invoiceDate": "2021-03-25T13:07:32Z",
    "dueDate": "2020-12-30T21:35:58Z",
    "debitorId": "217017",
    "invoiceNumber": "264639",
    "invoiceText": "Enough",
    "invoiceAmount": 83.95,
    "debitorName": "Breitenberg-Kozey"
  }, {
    "i": 15,
    "isSelected": false,
    "invoiceDate": "2021-07-29T18:44:22Z",
    "dueDate": "2020-11-21T09:54:02Z",
    "debitorId": "414175",
    "invoiceNumber": "472379",
    "invoiceText": "Rock-A-Doodle",
    "invoiceAmount": 41.93,
    "debitorName": "Ruecker Group"
  }, {
    "i": 16,
    "isSelected": false,
    "invoiceDate": "2021-05-15T15:24:50Z",
    "dueDate": "2021-03-04T01:42:41Z",
    "debitorId": "641830",
    "invoiceNumber": "759133",
    "invoiceText": "City Limits",
    "invoiceAmount": 15.78,
    "debitorName": "Aufderhar, Gutkowski and Rodriguez"
  }, {
    "i": 17,
    "isSelected": false,
    "invoiceDate": "2021-11-03T20:45:08Z",
    "dueDate": "2020-12-13T05:58:23Z",
    "debitorId": "330237",
    "invoiceNumber": "147087",
    "invoiceText": "Executive Suite",
    "invoiceAmount": 28.58,
    "debitorName": "Green and Sons"
  }, {
    "i": 18,
    "isSelected": false,
    "invoiceDate": "2021-08-31T08:26:33Z",
    "dueDate": "2021-04-01T02:18:39Z",
    "debitorId": "404348",
    "invoiceNumber": "434009",
    "invoiceText": "Save the Tiger",
    "invoiceAmount": 32.37,
    "debitorName": "Harvey Inc"
  }, {
    "i": 19,
    "isSelected": false,
    "invoiceDate": "2020-11-23T17:52:05Z",
    "dueDate": "2021-06-30T08:44:42Z",
    "debitorId": "331405",
    "invoiceNumber": "974723",
    "invoiceText": "Plot of Fear",
    "invoiceAmount": 95.85,
    "debitorName": "Cronin-Dickinson"
  }, {
    "i": 20,
    "isSelected": false,
    "invoiceDate": "2021-07-10T23:21:28Z",
    "dueDate": "2021-10-20T03:24:26Z",
    "debitorId": "447264",
    "invoiceNumber": "568365",
    "invoiceText": "Uuno in Spain",
    "invoiceAmount": 67.7,
    "debitorName": "Windler, Cummerata and Collier"
  }, {
    "i": 21,
    "isSelected": false,
    "invoiceDate": "2021-03-09T06:03:33Z",
    "dueDate": "2021-03-09T21:05:43Z",
    "debitorId": "306212",
    "invoiceNumber": "406762",
    "invoiceText": "Wild Bill",
    "invoiceAmount": 94.59,
    "debitorName": "Runolfsdottir-Murphy"
  }, {
    "i": 22,
    "isSelected": false,
    "invoiceDate": "2021-10-03T11:50:26Z",
    "dueDate": "2021-01-27T12:52:21Z",
    "debitorId": "561212",
    "invoiceNumber": "480285",
    "invoiceText": "Dan in Real Life",
    "invoiceAmount": 26.07,
    "debitorName": "Wilderman, Collier and Weber"
  }, {
    "i": 23,
    "isSelected": false,
    "invoiceDate": "2021-04-07T05:10:16Z",
    "dueDate": "2021-09-20T14:15:53Z",
    "debitorId": "767029",
    "invoiceNumber": "463942",
    "invoiceText": "One Hundred Mornings",
    "invoiceAmount": 78.59,
    "debitorName": "Corwin-Deckow"
  }, {
    "i": 24,
    "isSelected": false,
    "invoiceDate": "2021-04-13T23:34:18Z",
    "dueDate": "2021-02-28T19:04:27Z",
    "debitorId": "205087",
    "invoiceNumber": "635204",
    "invoiceText": "Pickup on South Street",
    "invoiceAmount": 11.27,
    "debitorName": "Bruen, Hoppe and Reynolds"
  }, {
    "i": 25,
    "isSelected": false,
    "invoiceDate": "2021-02-26T10:57:27Z",
    "dueDate": "2021-01-31T17:47:41Z",
    "debitorId": "946628",
    "invoiceNumber": "442793",
    "invoiceText": "Asterix in America (a.k.a Asterix Conquers America) (Astérix et les Indiens)",
    "invoiceAmount": 78.26,
    "debitorName": "Feest Group"
  }, {
    "i": 26,
    "isSelected": false,
    "invoiceDate": "2021-07-23T15:27:44Z",
    "dueDate": "2021-04-27T20:27:24Z",
    "debitorId": "732951",
    "invoiceNumber": "892101",
    "invoiceText": "1991: The Year Punk Broke",
    "invoiceAmount": 62.79,
    "debitorName": "Ankunding-Ortiz"
  }, {
    "i": 27,
    "isSelected": false,
    "invoiceDate": "2020-12-04T03:54:27Z",
    "dueDate": "2020-11-19T05:58:17Z",
    "debitorId": "225614",
    "invoiceNumber": "757753",
    "invoiceText": "Disconnect",
    "invoiceAmount": 26.22,
    "debitorName": "Pfannerstill LLC"
  }, {
    "i": 28,
    "isSelected": false,
    "invoiceDate": "2021-10-07T22:22:55Z",
    "dueDate": "2021-06-09T01:50:51Z",
    "debitorId": "244829",
    "invoiceNumber": "059253",
    "invoiceText": "Bad Biology",
    "invoiceAmount": 92.82,
    "debitorName": "Gutkowski-Leffler"
  }, {
    "i": 29,
    "isSelected": false,
    "invoiceDate": "2021-07-25T00:11:23Z",
    "dueDate": "2021-05-05T05:51:17Z",
    "debitorId": "590649",
    "invoiceNumber": "151206",
    "invoiceText": "American Ninja",
    "invoiceAmount": 4.19,
    "debitorName": "Swift-Maggio"
  }, {
    "i": 30,
    "isSelected": false,
    "invoiceDate": "2021-03-02T05:52:47Z",
    "dueDate": "2021-05-19T23:44:57Z",
    "debitorId": "303297",
    "invoiceNumber": "252760",
    "invoiceText": "Amber Lake ",
    "invoiceAmount": 60.03,
    "debitorName": "Jenkins, Hintz and Rogahn"
  }, {
    "i": 31,
    "isSelected": false,
    "invoiceDate": "2021-09-13T01:33:55Z",
    "dueDate": "2021-04-08T17:55:06Z",
    "debitorId": "793602",
    "invoiceNumber": "114110",
    "invoiceText": "Idiots and Angels",
    "invoiceAmount": 71.85,
    "debitorName": "Waters-Carter"
  }, {
    "i": 32,
    "isSelected": false,
    "invoiceDate": "2021-08-04T14:26:50Z",
    "dueDate": "2021-07-11T15:38:38Z",
    "debitorId": "426998",
    "invoiceNumber": "038349",
    "invoiceText": "Blood and Bones (Chi to hone)",
    "invoiceAmount": 15.7,
    "debitorName": "Bechtelar LLC"
  }, {
    "i": 33,
    "isSelected": false,
    "invoiceDate": "2021-04-08T00:23:26Z",
    "dueDate": "2020-12-02T06:23:28Z",
    "debitorId": "379174",
    "invoiceNumber": "945536",
    "invoiceText": "Hostel: Part II",
    "invoiceAmount": 96.41,
    "debitorName": "Trantow, Homenick and Bruen"
  }, {
    "i": 34,
    "isSelected": false,
    "invoiceDate": "2021-09-28T22:28:20Z",
    "dueDate": "2021-09-11T14:16:31Z",
    "debitorId": "839644",
    "invoiceNumber": "389615",
    "invoiceText": "Two for the Money",
    "invoiceAmount": 94.03,
    "debitorName": "Lowe-Conn"
  }, {
    "i": 35,
    "isSelected": false,
    "invoiceDate": "2021-07-18T13:19:51Z",
    "dueDate": "2021-01-21T21:25:04Z",
    "debitorId": "134325",
    "invoiceNumber": "513455",
    "invoiceText": "Fireworks (Hana-bi)",
    "invoiceAmount": 7.69,
    "debitorName": "Kiehn-VonRueden"
  }, {
    "i": 36,
    "isSelected": false,
    "invoiceDate": "2021-05-30T21:50:50Z",
    "dueDate": "2021-09-22T03:19:24Z",
    "debitorId": "458703",
    "invoiceNumber": "681068",
    "invoiceText": "Gideon's Army",
    "invoiceAmount": 28.32,
    "debitorName": "Sporer Inc"
  }, {
    "i": 37,
    "isSelected": false,
    "invoiceDate": "2021-05-10T17:25:26Z",
    "dueDate": "2021-11-01T02:04:06Z",
    "debitorId": "295022",
    "invoiceNumber": "693404",
    "invoiceText": "Three Fugitives",
    "invoiceAmount": 14.89,
    "debitorName": "Yost and Sons"
  }, {
    "i": 38,
    "isSelected": false,
    "invoiceDate": "2020-11-29T04:37:41Z",
    "dueDate": "2021-08-24T12:37:37Z",
    "debitorId": "420666",
    "invoiceNumber": "786673",
    "invoiceText": "This Christmas",
    "invoiceAmount": 46.09,
    "debitorName": "Bernier LLC"
  }, {
    "i": 39,
    "isSelected": false,
    "invoiceDate": "2020-11-24T13:25:57Z",
    "dueDate": "2020-12-18T18:10:30Z",
    "debitorId": "818012",
    "invoiceNumber": "671417",
    "invoiceText": "Mr. Troop Mom",
    "invoiceAmount": 9.09,
    "debitorName": "Runolfsdottir Inc"
  }, {
    "i": 40,
    "isSelected": false,
    "invoiceDate": "2021-08-01T14:32:47Z",
    "dueDate": "2021-11-12T03:47:17Z",
    "debitorId": "215224",
    "invoiceNumber": "516433",
    "invoiceText": "Remember the Day",
    "invoiceAmount": 35.13,
    "debitorName": "Kirlin-Larson"
  }, {
    "i": 41,
    "isSelected": false,
    "invoiceDate": "2021-07-13T12:13:18Z",
    "dueDate": "2020-12-26T09:14:04Z",
    "debitorId": "499611",
    "invoiceNumber": "452935",
    "invoiceText": "Glumov's Diary (Dnevnik Glumova)",
    "invoiceAmount": 22.24,
    "debitorName": "Keebler, Oberbrunner and Schmeler"
  }, {
    "i": 42,
    "isSelected": false,
    "invoiceDate": "2021-07-29T06:30:17Z",
    "dueDate": "2021-09-17T09:08:23Z",
    "debitorId": "200581",
    "invoiceNumber": "612799",
    "invoiceText": "Personal Best",
    "invoiceAmount": 83.0,
    "debitorName": "Feeney, Spinka and Balistreri"
  }, {
    "i": 43,
    "isSelected": false,
    "invoiceDate": "2021-07-24T23:28:41Z",
    "dueDate": "2021-01-22T10:47:20Z",
    "debitorId": "287296",
    "invoiceNumber": "936335",
    "invoiceText": "DMT: The Spirit Molecule",
    "invoiceAmount": 41.92,
    "debitorName": "Padberg, Brakus and Rosenbaum"
  }, {
    "i": 44,
    "isSelected": false,
    "invoiceDate": "2021-10-01T01:02:20Z",
    "dueDate": "2021-07-23T09:39:23Z",
    "debitorId": "283352",
    "invoiceNumber": "336319",
    "invoiceText": "Rollerball",
    "invoiceAmount": 54.93,
    "debitorName": "Kreiger, Heller and Rippin"
  }, {
    "i": 45,
    "isSelected": false,
    "invoiceDate": "2021-07-27T10:48:39Z",
    "dueDate": "2021-10-23T20:02:23Z",
    "debitorId": "499268",
    "invoiceNumber": "513568",
    "invoiceText": "Devil, The (Diabel)",
    "invoiceAmount": 56.64,
    "debitorName": "Auer and Sons"
  }, {
    "i": 46,
    "isSelected": false,
    "invoiceDate": "2021-04-05T14:28:06Z",
    "dueDate": "2021-07-21T06:15:40Z",
    "debitorId": "218613",
    "invoiceNumber": "611841",
    "invoiceText": "Murder of Crows, A",
    "invoiceAmount": 28.88,
    "debitorName": "Skiles-Boehm"
  }, {
    "i": 47,
    "isSelected": false,
    "invoiceDate": "2021-01-17T08:22:34Z",
    "dueDate": "2021-01-26T00:01:46Z",
    "debitorId": "823259",
    "invoiceNumber": "290102",
    "invoiceText": "Hip Hop Witch, Da",
    "invoiceAmount": 35.82,
    "debitorName": "Mitchell, Sauer and Morar"
  }, {
    "i": 48,
    "isSelected": false,
    "invoiceDate": "2021-04-17T05:18:20Z",
    "dueDate": "2021-07-01T12:32:22Z",
    "debitorId": "522796",
    "invoiceNumber": "363286",
    "invoiceText": "Band of Outsiders (Bande à part)",
    "invoiceAmount": 56.73,
    "debitorName": "Ondricka Group"
  }, {
    "i": 49,
    "isSelected": false,
    "invoiceDate": "2021-02-01T07:01:00Z",
    "dueDate": "2021-09-30T12:59:27Z",
    "debitorId": "938668",
    "invoiceNumber": "219458",
    "invoiceText": "Aliisa",
    "invoiceAmount": 25.94,
    "debitorName": "Ryan, Welch and Marquardt"
  }, {
    "i": 50,
    "isSelected": false,
    "invoiceDate": "2021-01-05T06:55:57Z",
    "dueDate": "2020-12-25T13:58:42Z",
    "debitorId": "445582",
    "invoiceNumber": "554090",
    "invoiceText": "Last Night",
    "invoiceAmount": 55.33,
    "debitorName": "Pollich, Leuschke and Walker"
  }]
  