import React, { useState, useEffect } from 'react';
import { StyledExpandRowTextTable, StyleInlineFkTable } from '../../../Styling/styledTable/StyledTable';
import UseTableSorter from '../../../components/useTable/UseTable'
import usePaginator, { StyledDropdown } from '../../../components/useTable/UsePaginator'
import { Pagination, Dropdown, Row } from 'react-bootstrap'
import { StengelisteHeaderComponent } from './StengelisteHeaderComponent';
import { StengelisteRowComponent } from './StengelisteRowComponent';
import { Button } from 'react-bootstrap';
import { BfButtonBlue } from '../../../Styling/styledForm/styledBlueForm';
import { ExcelSheet } from './StengelisteExcelWorksheet';
import { SelectedExcelSheet } from './StengelisteValgteExcelWorksheet';
import { dateToStringDMYHR } from '../../../util/date/dateRoutines';
import { BfRowForm } from '../../../Styling/styledForm/styledBlueForm'


const StengelisteTabell = (props) => {
    const localeMessages = props.localeMessages

    // Gjør sjekk for header component
    const isCheckboxVisible = props.tableData.some(item => item.status === 'NY' || item.status === 'SLETTET')
    const isPutOnHoldVisible = props.tableData.some(item => item.status === 'NY' || item.status === 'SENDT STENGING')
    const isOnHold = props.tableData.some(item => item.status === 'SLETTET')
    const isOnList = props.tableData.some(item => item.status === 'NY')
    const isPaymentReceivedVisible = props.tableData.some(item => item.status === 'BETALT ETTER STENGING')
    const isSelectedDateVisible = props.tableData.some(item => item.status === 'SENDT STENGING' || item.status === 'BETALT ETTER STENGING')
    const totalListLength = props.tableData.length


    const lastUpdatedDate = props.tableData.find(item => item.updatedDate)?.updatedDate;
    const formattedDate = dateToStringDMYHR(lastUpdatedDate)

    // Liste av alle som nettopp har blitt sendt inn til stenging. Blir brukt til excel eksport av valgte.
    // Blir nullstilt om stengelisten blir hentet på nytt eller bruker bytter tab, osv
    const selectedForClosing = props.state.closingList.filter(e => e.isSelected)

    // Om bruker ser på listen med Status BETALT ETTER STENGING skal den være sortert basert på paymentDate med den nyeste først. 
    // Under Valgt for stenging blir de sortert på Plukket for stenging datoen, med nyeste først
    // Under På stengeliste og På hold blir de sortert på regDate med nyeste først
    // State for grid sorting
    const [currentSeq, setCurrentSeq] = useState('down') // default
    const [currentField, setCurrentField] = useState('regDate'); // Default sorting field    
    const [currentType, setCurrentType] = useState('datetime') // num

    // Fikser en bug for når saker ble satt på hold så ble listen sin sortering satt til feil field
    useEffect(() => {
        if (isOnList || isOnHold) {
            setCurrentField('regDate');
        } else if (isPaymentReceivedVisible) {
            setCurrentField('paymentDate');
        } else {
            setCurrentField('closingDate');
        }
    }, [isOnList, isOnHold, isPaymentReceivedVisible]);

    // state for grid paginator
    const [numberPrPage, setNumberPerPage] = useState(10)
    const [activePage, setActivePage] = useState(1);
    const [showAll, setShowAll] = useState(false);

    const handleShowAllClick = () => {
        setShowAll(!showAll);
        if (showAll) {
            setNumberPerPage(10);
        } else {
            setNumberPerPage(totalListLength);
        }
    };


    const [t1ChangeSortField, t1GetSortFunc, t1IndicaterIcon] = UseTableSorter(
        currentSeq, setCurrentSeq,
        currentField, setCurrentField,
        currentType, setCurrentType
    )

    // Point to data 
    const tableData = props.tableData
    const selectorValues = props.selectorValues

    // Setup paginator
    const [Paginate, PaginationNav, PaginationDropdown, PaginationIndex, resetPagination] = usePaginator(
        tableData.sort(t1GetSortFunc().fn), // <==== sorting inputdata =========
        numberPrPage, setNumberPerPage,
        activePage, setActivePage
    )

    return (
        <>
            <BfRowForm style={{ width: '100%', margin: '0px', padding: '20px 0px 10px 0px' }}>
                <PaginationDropdown />
            </BfRowForm>

            <StyledExpandRowTextTable>
                <StengelisteHeaderComponent
                    showCheckbox={isCheckboxVisible}
                    showPutOnHold={isPutOnHoldVisible}
                    showPaymentDate={isPaymentReceivedVisible}
                    showSelectedDate={isSelectedDateVisible}
                    localeMessages={localeMessages}
                    t1ChangeSortField={t1ChangeSortField}
                    t1IndicaterIcon={t1IndicaterIcon}
                />

                <tbody>
                    <Paginate>
                        {/* paginate forventer en funksjon */}
                        {(row, i) => (<StengelisteRowComponent
                            row={row}
                            toggleRow={props.toggleRow}
                            toggleOnHoldRow={props.toggleOnHoldRow}
                            toggleMovedRow={props.toggleMovedRow}
                            showCheckbox={isCheckboxVisible}
                            showSelectedDate={isSelectedDateVisible}
                            showPaymentDate={isPaymentReceivedVisible}
                            showPutOnHold={isPutOnHoldVisible}
                            localeMessages={localeMessages}
                            stFuncs={props.stFuncs} />)}
                    </Paginate>
                </tbody>

            </StyledExpandRowTextTable>
            {props.tableData.length === 0 && <span>{localeMessages.ingenPåListen}</span>}

            {props.tableData.length !== 0 &&
                <>
                    <br />
                    <PaginationIndex />

                    <br />
                    <span>{localeMessages.SistOppdatert}<b>{formattedDate}</b></span>
                    <br /><br />
                    <PaginationNav />

                    <BfButtonBlue variant="primary" type="button" onClick={handleShowAllClick}>
                        {showAll ? localeMessages.Reduser : localeMessages.VisAlle}
                    </BfButtonBlue>{' '}
                    {/* Bruker isCheckboxVisible til å skjule knapper da det er kun ved status NY knappene trenger være synlig */}
                    {isCheckboxVisible && (
                        <BfButtonBlue variant="primary" type="button" onClick={() => props.toggleAllRows(true)}>
                            {localeMessages.MerkAlle}
                        </BfButtonBlue>)}{' '}
                    {isCheckboxVisible && (
                        <BfButtonBlue variant="primary" type="button" onClick={() => props.toggleAllRows(false)}>
                            {localeMessages.MerkIngen}
                        </BfButtonBlue>)}{' '}

                    {selectedForClosing.length > 0 ?
                        <SelectedExcelSheet
                            filename={props.selectedKreditor}
                            headerState={props.headerState}
                            data={selectedForClosing}
                            localeMessages={props.localeMessages}
                        /> :
                        <ExcelSheet
                            filename={props.selectedKreditor}
                            headerState={props.headerState}
                            data={props.state.closingList}
                            localeMessages={props.localeMessages}
                        />}{' '}

                    {/* {selectedForClosing.length > 0 ?
                        <SelectedExcelSheet
                            filename={props.selectedKreditor}
                            headerState={props.headerState}
                            data={selectedForClosing}
                            localeMessages={props.localeMessages}
                        /> : isPutOnHoldVisible && <BfButtonBlue variant="primary" type="button"
                            disabled={true}
                        >
                            {"Excel innsendte"}
                        </BfButtonBlue>}{' '} */}

                    {isCheckboxVisible && (
                        <BfButtonBlue variant="primary" type="button"
                            disabled={props.state.antall_valgte === 0}
                            onClick={() => isOnHold ? props.fbFuncs.showMoveDialog() : props.bsFuncs.showDialog()}>
                            {isOnHold ? localeMessages.Flytt : localeMessages.Send}
                        </BfButtonBlue>)}{' '}


                </>
            }

        </>
    )
}

export default StengelisteTabell



