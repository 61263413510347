export const messages = {
    nb: {
        Header1: `Opprette ny sak`,
        Paragraph1: `For å opprette en ny sak på eksisterende debitor, må du først velge en kreditor i nedtrekkslisten på toppen. Deretter kan du velge en eksisterende debitor. og gå videre til neste bilde.`,
        Paragraph2: `For å opprette en ny sak på en ny debitor, klikk på knapp for "Ny Debitor".`,

        GH_birthDate: 'F.dato',
        GH_caseNumber: 'Fødselsnr/Orgnnr',
        GH_debtorName: 'Navn',
        GH_debtorNo: 'Debitornummer',
        GH_email: 'Epost',
        GH_postalCode: 'Postnr',
        GH_postalPlace: 'Poststed',
        GH_creditScore: 'Kredittscore',
        GH_date_creditScore: 'Dato kredittscore',

        filterNone: 'Det er ingen debitorer som oppfyller kriteriene',
        kreditorNoSel: 'Ingen kreditor er valgt.',

        toastGetDebitorsFailed: 'Henting av debitorer for valgt kreditor feilet. ',
        toastGetDebitorsOk: 'Hentet debitorer for valgt kreditor',

        toastGetBasicDataFailed: 'Basisdata (valutakoder, landkoder og postnummerlist) feilet. ',
        toastGetBasicDataOk: 'Basisdata (valutakoder, landkoder og postnummerlist) hentet.',

        toastNySakPostDebitorFailed: 'Kunne ikke opprette midlertidig sak. ',
        toastNySakPostDebitorOk: 'Ny sak er opprettet med status midlertidig. ',

        toastNySakFakturaUploadFailed: 'Opplasting av faktura feilet. ',
        toastNySakFakturaUploadOk: 'Faktura er lastet opp, og lagt på midlertidig sak. ',

        toastNySakFakturaDeletedFailed: 'Kunne ikke slette faktura fra midlertidig sak. ',
        toastNySakFakturaDeletedOk: 'Faktura er slettet fra midlertidig sak. ',

        toastNySakFinalizeOk: 'Ny sak er regstrert.',
        toastNySakFakturaFinalizeFailed: 'Kan ikke registrere ny sak. ',

        toastGetCMSailed: 'Henting av CMS innhold feilet. ',
        toastGetCMSOk: 'CMS Innhold er hentet fra server.',

        toastGetCompanyRatingFailed: 'Henting av kredittvurdering feilet. ',
        toastGetCompanyRatingOk: 'Hentet kredittvurdering for orgnummer ',

        toastGetPersonRatingFailed: 'Henting av kredittvurdering feilet. ',
        toastGetPersonRatingOk: 'Hentet kredittvurdering for personnummer ',

        toastGetRatingInfoFailed: 'Sjekk om kreditor kan utføre kredittsjekk feilet. ',
        toastGetRatingInfoOk: 'Denne kreditor kan utføre kredittsjekk. ',

        toastGetBislabTermsFailed: 'Henting av avtale feilet. ',
        toastGetBislabTermsOk: 'Hentet avtale for signering. ',

        toastAcceptRatingFailed: 'Signering av avtale feilet for kreditor ',
        toastAcceptRatingOk: 'Avtalen har blitt signert for kreditor  ',

        toastUpdateDebtorRatingFailed: 'Oppdatering av kredittvurdering feilet for debitor ',
        toastUpdateDebtorRatingOk: 'Kredittvurdering har blitt oppdatert for debitor ',

        produktTypeLabel: `Produkt`,
        produktTypePlaceholder: `Velg produkttype`,
        produktTypeSmall: `Om det ikke er sendt inkassovarsel, må "Inkassovarsel" velges.`,

        debtorTypeLabel: `Type`,
        debtorTypePlaceholder: ``,
        debtorTypeSmall: `Velg privat eller næring`,
        debtorTypeisRequired: 'Du må velge type. ',

        debtorNameLabel: `Navn`,
        debtorNamePlaceholder: `Oppgi navn på debitor`,
        debtorNameSmall: `Oppgi firmanavn/fornavn mellomnavn etternavn. `,

        firstNameLabel: `Fornavn`,
        firstNamePlaceholder: `Oppgi fornavn`,
        firstNameSmall: `Oppgi debitors fornavn. `,

        lastNameLabel: `Etternavn`,
        lastNamePlaceholder: `Oppgi etternavn`,
        lastNameSmall: `Oppgi debitors etternavn. `,

        debtorNameLabel: `Navn`,
        debtorNamePlaceholder: `Oppgi navn på debitor`,
        debtorNameSmall: `Trykk på "Hent info" eller oppgi firmanavn/fornavn mellomnavn etternavn. `,

        produktTypeisRequired: 'Påkrevd ',
        debtorNameisRequired: 'Navn må fylles ut. ',
        firstNameisRequired: 'Fornavn må fylles ut. ',
        lastNameisRequired: 'Etternavn må fylles ut. ',
        minLength3: 'Minimum 3 tegn. ',

        maxLength: 'Maksimum antall tegn er ',

        debtorIdentificationNumberLabel: `Orgnr`,
        debtorIdentificationNumberPlaceholder: `Oppgi orgnr`,
        debtorIdentificationNumberSmall: `9 sifret organisasjonsnummer`,
        notValidOrgnr: 'Ikke gyldig orgnr (9 sifre)',

        birthDateLabel: `Fødselsdato`,
        birthDatePlaceholder: `Oppgi fødselsdato`,
        birthDateSmall: `dd.mm.yyyy`,

        birthNumberLabel: `Personnr`,
        birthNumberPlaceholder: `Oppgi personnr`,
        birthNumberSmall: `Oppgi personnr`,

        debtorNoLabel: `Nr`,
        debtorNoPlaceholder: ``,
        debtorNoSmall: `Registrert debitornr`,

        emailLabel: `Epostadresse`,
        emailPlaceholder: `Oppgi gyldig epostadresse`,
        emailSmall: `Epostadresse til debitor`,
        notValidEmail: 'Ugyldig epostadresse. ',

        phoneLabel: `Telefonnr`,
        phonePlaceholder: `Oppgi gyldig telefonnr`,
        phoneSmall: `Maks 15 siffer`,

        referanseNummerLabel: `Referansenummer/kundenummer`,
        referanseNummerPlaceholder: `Oppgi referansenummer/kundenummer`,
        referanseNummerSmall: `Oppgi en kundereferanse som er UNIK i deres økonomisystem (fritekst). `,
        referanseNummerIsRequired: 'Du må oppgi en referanse. ',

        postalAddressLabel: `Postadresse`,
        postalAddressPlaceholder: `Oppgi postadresse`,
        postalAddressSmall: `Postadresse/Postboks`,
        postalAddressisRequired: 'Postadresse må oppgis',

        streetAddressLabel: `Gateadresse/Besøksadresse`,
        streetAddressPlaceholder: `Oppgi besøksadresse`,
        streetAddressSmall: `Ekstra info når postboks adresse brukes`,

        postalCodeLabel: `Postnummer`,
        postalCodePlaceholder: ``,
        postalCodeSmall: `Postnummer`,
        postalCodeisRequired: 'Postnummer må oppgis. ',

        postalPlaceLabel: `Poststed`,
        postalPlacePlaceholder: `Oppgi poststed`,
        postalPlaceSmall: `Poststed for debitor`,
        postalPlaceisRequired: 'Poststed må oppgis. ',

        butTilbake: "Tilbake",
        Lagre: 'Gå videre',
        Tilbake: 'Tilbake til debitorsøk',
        LeggTilFaktura: 'Legg til faktura',
        SendInn: 'Send inn',
        Oppdater: 'Lagre endringer',
        ClearForm: 'Tøm',     
        HentInfo: 'Hent info',   

        Label: ``,
        Placeholder: ``,
        Small: ``,

        youHaveErrors: 'Du har feil i skjema som du må korrigere før du kan gå videre for å laste opp fakturaer.',

        fakturanummerLabel: `Fakturanummer`,
        fakturanummerPlaceholder: `Oppgi fakturanummer`,
        fakturanummerSmall: `Opprinnelig fakturanummer`,
        fakturanummerIsRequired: 'Må fylles ut. ',

        kidLabel: `KID`,
        kidPlaceholder: `Oppgi KID`,
        kidSmall: `Oppgi eventuell KID`,

        fakturatekstLabel: `Fakturatekst`,
        fakturatekstPlaceholder: `Oppgi fakturatekst/beskrivelse`,
        fakturatekstSmall: `Faktura tekst/ beskrivelse`,
        fakturatekstIsRequired: 'Må fylles ut. ',

        forfallsdatoLabel: `Forfallsdato`,
        forfallsdatoPlaceholder: `DD.MM.YYYY`,
        forfallsdatoSmall: `Oppgi opprinnelig forfall(dd.mm.yyyy)`,
        forfallsdatoIsRequired: 'Må fylles ut. ',
        isBeyondToday: 'Er større enn dagens dato. ',
        isBeyondThreeYearsOld: 'Mer enn tre år siden. ',
        isTooOld: 'Dato er ugyldig. ',
        minLength10: 'Må inneholde 10 tegn. ',
        isForfallOk: 'Forfall kan ikke være før fakturadato',

        fakturadatoLabel: `Fakturadato`,
        fakturadatoPlaceholder: `DD.MM.YYYY`,
        fakturadatoSmall: `Oppgi fakturadato(dd.mm.yyyy)`,
        fakturadatoIsRequired: 'Må fylles ut. ',

        valutaLabel: `Valuta`,
        valutaPlaceholder: `Oppgi valuta`,
        valutaSmall: 'Valg fra listen',
        valutaIsRequired: 'Må fylles ut. ',

        belopLabel: `Beløp`,
        belopPlaceholder: `0, 00`,
        belopSmall: `Oppgi fakturabeløp`,
        belopIsRequired: 'Må fylles ut. ',

        caseMessageLabel: 'Kommentarfelt',

        velgFilLabel: `Dersom du vil laste opp et dokument, klikk på knapp og velg fil.`,

        SK_Head: '1: Registrer ny sak - debitor informasjon',
        SK_Caption: 'Oppgi data for sak. Når du er klar, kan du klikke på "Registrer fakturaer" for å gå videre. Saken blir midlertidig lagret, og kan ikke endres, men saken blir ikke endelig opprettet før du er ferdig med fakturabildet.',

        FK_Head: '2: Registrer ny sak - Registrer fakturaer',
        FK_Caption: 'Registrer en og en faktura, klikk "Legg til faktura" for å registrere og laste opp vedlegg. Fakturaene blir midlertidig lagret, og kan ikke endres, men saken blir ikke endelig opprettet før du er ferdig med fakturabildet.',

        FL_Head: 'Registerte fakturaer',
        FL_Caption: 'Følgende fakturaer er registert. Klikk på fakturanummer for å endre. Klikk på ikon for å slette.',
        FL_Caption2: 'Du har ingen fakturaer er registert på saken ennå. Du kan ikke sende saken inn før du har registrert minst en faktura.',

        ReceiptH1: 'Faktura er registrert',
        Receipt1: 'Vi takker for oppdraget. Faktura er nå registret og saken vil dukke opp i sakslisten innen kort tid.',
        Receipt2a: 'Bruk "refresh"-knappen i ',
        Receipt2b: ' for oppdatert liste.',

        fg_linje: 'Linje',
        fg_fnr: 'Fakturanummer',
        fg_fdato: 'Fakturadato',
        fg_forf: 'Forfall',
        fg_tekst: 'Tekst',
        fg_vedlegg: 'Vedlegg',
        fg_kid: 'KID',
        fg_val: 'Valuta',
        fg_bel: 'Beløp',
        fg_slett: 'Slett faktura ',
        fg_edit: 'Rediger faktura ',

        arth: 'Tidligere registrerte debitorer',
        artd1: `Har du valgt den riktige kreditoren fra nedtrekkslisten oppe til høyre?`,
        artd2: `Ønsker du å registrere en sak på en av de tidligere registrerte debitorene? Velg aktuell debitor i listen under eller søk oppe til høyre og velg i søkelisten.`,
        artd3: `Ønsker du å registrere en sak på en ny debitor ? Velg knappen «ny debitor» oppe til høyre og skriv inn info.`,

        md_Header1: 'Referansenr er benyttet på debitor',
        md_Message: 'Dette referansenummere har du tidligere benyttet på en annen debitor, vil du heller fylle ut feltene i skjemaet med denne debitoren?',
        md_header1: '',
        md_Ok: 'Ja',
        md_Cancel: 'Nei',
      
        accept_terms: 'Godta avtale',
        terms_caption: 'For å kunne gjøre kredittsjekk må du først lese og godta avtalen under.',
        checkbox_text: 'Jeg godtar avtalen',
        accept: 'Godta',
        cancel: 'Avbryt',

        do_creditCheck: 'Utfør kredittsjekk',
        credit_check: 'Kredittsjekk',
        credit_ratingLabel: 'Karakter',
        credit_scoreLabel: 'Score',
        credit_colorLabel: 'Vurdering',
        score_explanationLabel: 'Forklaring',
        recommended_limitLabel: 'Anbefalt kredittgrense',
        creditCheck_required: 'Velg Type og fyll ut Orgnr om Næring eller Personnr om Person ovenfor',
        navigate_creditTab: 'Til Kredittvurdering',

        must_accept_terms: 'For å kunne gjøre kredittsjekk må du første lese og godkjenne avtalen.',

        toastFirmainfoNon200: 'Ingen firmainformasjont funnet i Brønnøysundregistrene.',
        toastFirmainfoError: 'Kunne ikke hente firmainformasjon fra Brønnøysundregistrene med oppgitt orgnr ',
        toastFirmaIntoHentet: 'Firmainformasjon ble hentet fra Brønnøysundregistrene.',

    }
}

