// REDUX Variant
import { toast } from 'react-toastify';
import _ from 'lodash'
import axiosTokenInstance from '../../Instances/axiosTokenInstance';

/*
To use:
   import { useSelector, useDispatch } from 'react-redux'
   import { ..exported dispatchers... } from '../../reduxStore/...';

   // Angi ønsket redux store du vil hente state fra
   const state = useSelector(state => state.oversikt)

   // Få tak i redux dispatheren som du evt kan gi eget navn
   const _dispatch = useDispatch()
    ..

   // Kjør kall for å endre state
   _dispatch(setSomeShit(shitparam1))

*/


export const actions = {
    OVERSIKT_LOADING: 'OVERSIKT_LOADING',
    OVERSIKT_ERROR: 'OVERSIKT_ERROR',
    OVERSIKT_DATA_FETCHED: 'OVERSIKT_DATA_FETCHED',
}

const getErrorMessage = (err) => {
    if (_.has(err, 'response.data.title')) {
        return err.response.data.title
    }
    else if (_.has(err, 'response.data')) {
        return err.response.data
    }
    else if (_.has(err, 'response.statusText')) {
        return err.response.statusText
    }
    else {
        return err.message.toString()
    }
}

const mapDataToLists = ((taskList) => {

    let oppgaveListe = []
    let besvarteListe = []

    taskList.map((e, i) => {
        const item = {
            i: i,
            caseNo: e.caseNo,
            caseReference: e.caseReference,
            caseStatus: e.caseStatus,
            creditorNo: e.creditorNo,
            creditorName: e.creditorName,
            debtorName: e.debtorName,
            lastCaseNote: e.lastCaseNote,
            messageDirection: e.taskDescription,
            messageText: e.messageText,
            regDate: e.regDate,
            taskId: e.taskId,

            invoiceList: [...e.invoiceList],
            invoiceListLength: e.invoiceList.length,

            isHistorical: e.isHistorical,
            isInformation: e.isInformation,
            isReplyable: e.isReplyable,
        }

        if (e.isHistorical) {
            besvarteListe.push(item)
        } else {
            oppgaveListe.push(item)
        }
    })

    return { oppgaveListe, besvarteListe }

})

export const sendMelding = (caseModel, newState, showToasts, messages) => {

    return dispatch => {
       
        dispatch({ type: actions.OVERSIKT_LOADING, data: null })

        let failedToast = messages['toastSendMeldingFailed']
        let okToast = messages['toastMeldingUploadOk']

        axiosTokenInstance
            ({
                method: 'POST',
                url: `api/sak/PostUpdateCreateTask`,
                data: caseModel
            })
            .then((result) => {
                //console.log(result)
                if (result.status !== 200) {
                    (showToasts && toast.error(failedToast, {autoClose: false}))
                    dispatch({ type: actions.OVERSIKT_ERROR, data: failedToast })
                } else {
                    if (result.data.errorCode === "000") {
                        (showToasts && toast.warning(okToast))

                        // Her må begge state arrayene oppdateres
                        dispatch({type: actions.OVERSIKT_DATA_FETCHED,  payload: newState })

                    } else {
                        (showToasts && toast.warning(failedToast + ', ' + result.data.errorMessage))
                    }
                }

            })
            .catch((err) => {
                let errormessage = failedToast + ' ' + getErrorMessage(err)
                toast.error(errormessage, {autoClose: false})
                dispatch({ type: actions.OVERSIKT_ERROR, data: errormessage })
            })        
    }    
}


export const getOversiktDataDisp = (selectedKreditor, showToasts) => {

    return dispatch => {

        const errorMessage = 'Kunne ikke hente data til oversiktsbildet. (redux:oversikt)'
        const okMessage = 'Hentet data til oversiktsbildet.' 

        dispatch({ type: actions.OVERSIKT_LOADING, data: null })

        axiosTokenInstance
            ({
                method: 'GET',
                url: `/api/oversikt/GetKreditorTasks/${selectedKreditor}`,
            })
            .then((result) => {

                let data = JSON.parse(result.data)
                
                if (data.errorCode === "000") {

                    (showToasts && toast.info(okMessage))

                    // Map om data                  
                    const { oppgaveListe, besvarteListe } = mapDataToLists(data.creditorTaskList)

                    dispatch({
                        type: actions.OVERSIKT_DATA_FETCHED,
                        payload: {
                            oppgaveListe,
                            besvarteListe,
                            avptHa: data.averageProcessingTimeCaseHandler,
                            avptCr: data.averageProcessingTimeCreditor
                        }
                    })
                } else {
                    (showToasts && toast.warning(errorMessage + ', ' + result.data.errorMessage))
                    dispatch({ type: actions.OVERSIKT_ERROR, error: result.data.errorMessage })
                }               
            })
            .catch((err) => {
                let lErrMess = errorMessage + ' ' + getErrorMessage(err)
                toast.error(lErrMess, {autoClose: false})
                dispatch({ type: actions.OVERSIKT_ERROR, error: lErrMess })
            })

    }
}