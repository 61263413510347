export const messages = {
    nb: {

        TaC_T1: 'Oppgaver',
        TaC_T2: 'Besvarte meldinger',
        TaC_T3: 'Inkassoakademiet',
        TaC_T4: 'Nyheter',
        Ex: `text`,

        ingen: 'Ingen',

        toastGetOversiktFailed: 'Henting av data feilet. ',
        toastGetOversiktOk: 'Oppgaver og meldinger er hentet fra server.',

        toastGetCMSailed: 'Henting av CMS innhold feilet. ',
        toastGetCMSOk: 'CMS Innhold er hentet fra server.',

        toastSendMeldingFailed: 'Sending av melding feilet. ',
        toastMeldingUploadOk: 'Melding er sendt. ',

        HA1: 'Antall ubehandlede oppgaver',
        HB1: 'Antall uleste informasjonsmeldinger',
        HC1: 'Gjennomsnittlig besvarelsestid Kreditorforeningen',
        HD1: 'Gjennomsnittlig besvarelsestid kreditor',
        HDAGER: ' dager',


        GH_regDate: 'Registrert dato',
        GH_caseNo: 'Saksnr',
        GH_debtorName: 'Debitor navn',
        GH_creditorNo: 'Kreditornr',
        GH_creditorName: 'Kreditornavn',
        GH_caseReference: 'Ref.nr.',
        GH_messageDirection: 'Beskrivelse',
        GH_caseStatus: 'Status',

        GHF_linenumber: 'Linje',
        GHF_invoiceDate: 'Dato',
        GHF_dueDate: 'Forfall',
        GHF_invoiceNumber: 'Fakuranr.',
        GHF_description: 'Beskrivelse',
        GHF_originalCapital: 'Beløp',
        GHF_paidCapital: 'Innbetalt',
        GHF_interest: 'Rente',
        GHF_balanceInterest: 'Renter',
        GHF_balanceCapital: 'Saldo',

        ML1M_Overskrift: 'Les melding',
        ML1M_sak: 'Sak',
        ML1M_oppgave: 'Oppgave',
        ML1M_Ok: 'Marker lest',
        ML1M_Avbryt: 'Avbryt',
        ML1M_Lukk: 'Lukk vindu',

        MS1M_Overskrift: 'Svar på melding',
        MS1M_sak: 'Sak',
        MS1M_melding: 'Melding',
        MS1M_svar: 'Svar',
        MS1M_Ok: 'Send svar',
        MS1M_Avbryt: 'Avbryt',

        editColumns: 'Rediger kolonner',
        stopEditing: 'Stopp redigering',
        resetColumns: 'Tilbakestill kolonner',
        hideColumns: 'Skjul valgte'
    }
}