import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getAhlinCMSbyId } from '../../../reduxStore/HeadlessCMS/headlessCmsActions';
import { useSelector, useDispatch } from 'react-redux';
import { OverlaySpinner } from '../../../components/spinner/OverlaySpinner';


const KredittsjekkCMS = (props) => {
    const [content, setContent] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [articleFetched, setArticleFetched] = useState(false);

    const localeMessages = props.localeMessages
    const articleId = props.articleId
    const showToasts = false;

    const dispatch = useDispatch()

    useEffect(() => {
        let isMounted = true;
        if (!articleFetched) {
            const fetchData = async () => {
                try {
                    const data = await dispatch(getAhlinCMSbyId(articleId, showToasts, localeMessages));
                    if (isMounted) {
                        setContent(Array.isArray(data) ? data : []);
                        setArticleFetched(true);
                        setLoading(false);
                    }
                } catch (err) {
                    if (isMounted) {
                        setError(err.message);
                        setLoading(false);
                    }
                }
            };

            fetchData();

            return () => {
                isMounted = false;
            };
        }
    }, [articleId, articleFetched, dispatch, showToasts, localeMessages]);


    if (loading) return <OverlaySpinner></OverlaySpinner>;
    if (error) return <p>Noe gikk galt ved henting av artikkel</p>;

    return (
        <div>
            {content && content.length > 0 ? (
                content.map((item) => (
                    <div key={item.id}>
                        <h1 dangerouslySetInnerHTML={{ __html: item.title.rendered }}></h1>
                        <div dangerouslySetInnerHTML={{ __html: item.content.rendered }}></div>
                    </div>
                ))
            ) : (
                <div style={{ color: 'red', textAlign: 'center', fontWeight: '600', fontFamily: "Fellix, sans-serif", fontSize: '20px' }}>
                    Noe gikk galt ved henting av informasjon, du kan fortsatt bruke Kunderportalen som vanlig.
                </div>
            )}
        </div>
    );
};

export default KredittsjekkCMS;
