import React, { useEffect, useState } from 'react'
import { useUcForm } from '../../../hooks/useUcForm';
import { postNySakDebitor, setDebitor } from '../../../reduxStore/newCase/newCaseActions';
import { GetFormValidators, GetFormValidatorsNew } from './debitorFormValidators'

const useDebitorForm = (state, dispatch, showToast, localeMessages) => {
          
    // Sjekk på state for å inialisere formverdier
    let formValues = {
        produktType: state.defaultProduct ? state.defaultProduct : '',
        
        referanseNummer: '',
        birthDate: '',
        birthNumber: '',
        debtorIdentificationNumber: '',
        
        debtorName: '',
        firstName: '',
        lastName: '',
        debtorNo: '',
        debtorType: '',
        
        email: '',
        extraAddress: '',
        phone: '',
        postalAddress: '',
        postalCode: '',
        postalPlace: '',
        countryCode: 'NO',        
        streetAddress: '',
        caseMessage: '',
    }


    const formatDate = d => {
        if ((d) && (d.length >= 20)) {
            return `${d.substring(8, 10)}.${d.substring(5, 7)}.${d.substring(0, 4)}`
        }
        return ''
    }

    // Husk referanse
    const [selectedReferanse, setSelectedReferanse] = useState(null)
    const [confirmModalVisible, setConfirmModalVisible] = useState(false)
    const [altDebitor, setAltDebitor] = useState(null)
    
    // Sjekke om vi kan sette et default referansenr 
    // fordi du kun hadde en customer på debitor
    useEffect(() => {
        console.log("Debitor er endret: ", state.selectedDebitor.debtorNo)
        if ((state.selectedDebitor.customerNoList) && (state.selectedDebitor.customerNoList.length === 1)) {
            setField ('referanseNummer', state.selectedDebitor.customerNoList[0])
            setSelectedReferanse(state.selectedDebitor.customerNoList[0])
        }
    }, [state.selectedDebitor.debtorNo])

    const handleBlur = (e) => {
        if (e.target.name==='referanseNummer') {
            handleRefNrExit(e)            
        }
    }

    const handleRefNrExit = (e) => {
        console.log('refnr onBlur', e.target.name)
        console.log('singleCustSelDeb: ', selectedReferanse)
        console.log('Value: ', e.target.value)
        if (e.target.value && (selectedReferanse !== e.target.value)) {
            checkUseOfRefnr(e.target.value)       
        }
    }

    const checkUseOfRefnr = (refnr) => {
        let foundReferanse       
        let found
        for (let debtor of state.debitorList) {
            for (let custno of debtor.customerNoList) {
                console.log('Custno', custno)
                if (custno.toString() === refnr) {
                    found = true; 
                    foundReferanse = debtor
                    setAltDebitor(debtor)
                    setSelectedReferanse(refnr)
                    break
                }
                if (found) break
            }
        }
        if (found) {            
            setConfirmModalVisible(true)  
        }
    }

    const confirmModalOk = (debitor) => {
        dispatch(setDebitor(altDebitor))
        setFieldsFromDebitor(altDebitor)
        setConfirmModalVisible(false)
    }

    const confirmModalCancel = () => {
        setField ('referanseNummer', '')
        setAltDebitor(null)
        setConfirmModalVisible(false)
    }

    // Fix trøbbel med validator ved kopiering av exist debitor
    const addSpacesToDebtorNo = (debtorNo) => {
        var string = debtorNo;
        var chunk_size = 3;
        var insert = ' ';

        // Reverse it so you can start at the end
        string = string.split('').reverse().join('');

        // Create a regex to split the string
        const regex = new RegExp('.{1,' + chunk_size + '}', 'g');

        // Chunk up the string and rejoin it
        string = string.match(regex).join(insert);

        // Reverse it again
        string = string.split('').reverse().join('');

        return string
    }

    const setFieldsFromDebitor = (debitor) => {        
        if (debitor.debtorType === 'Næring') 
        {            
            setField('debtorType', 'Næring')            
            setField ('debtorIdentificationNumber', addSpacesToDebtorNo(debitor.debtorIdentificationNumber))
            setField ('birthDate', '')
            setField ('birthNumber', '')
        }  
        else if (debitor.debtorType === 'Privat') {                  
            setField('debtorType', 'Privat')
            setField ('debtorIdentificationNumber', '')                                    
            setField ('birthDate', formatDate(debitor.birthDate))
            setField ('birthNumber', debitor.birthNumber)
        }                
        setField ('debtorName', debitor.debtorName)
        setField ('firstName', debitor.firstName)
        setField ('lastName', debitor.lastName)
        setField ('debtorNo', debitor.debtorNo)
        setField ('debtorType', debitor.debtorType)
        
        setField ('email', debitor.email)
        setField ('extraAddress', debitor.extraAddress)
        setField ('phone', debitor.phone)
        setField ('streetAddress', debitor.streetAddress)
        setField ('postalAddress', debitor.postalAddress)
        setField ('postalCode', debitor.postalCode)
        setField ('postalPlace', debitor.postalPlace)
        setField ('countryCode', debitor.countryCode)
        setField ('caseMessage', '')    
    }

    // Sjekker om objektet har properties (existing)
    if (Object.keys(state.selectedDebitor).length !== 0) {
        
        formValues = {
            produktType: state.selectedDebitor.defaultProduct ? state.selectedDebitor.defaultProduct : '',
            referanseNummer: state.selectedDebitor.referanseNummer ? state.selectedDebitor.referanseNummer : '',

            birthDate:  formatDate(state.selectedDebitor.birthDate), //state.selectedDebitor.birthDate,
            birthNumber: state.selectedDebitor.debtorIdentificationNumber,
            debtorIdentificationNumber: state.selectedDebitor.debtorIdentificationNumber,

            debtorName: state.selectedDebitor.debtorName,
            firstName: state.selectedDebitor.firstName,
            lastName: state.selectedDebitor.lastName, // <== Firmanavn her...
            debtorNo: state.selectedDebitor.debtorNo,
            debtorType: state.selectedDebitor.debtorType,

            email: state.selectedDebitor.email,
            extraAddress: state.selectedDebitor.extraAddress,
            phone: state.selectedDebitor.phone,
            streetAddress: state.selectedDebitor.streetAddress,
            postalAddress: state.selectedDebitor.postalAddress,
            postalCode: state.selectedDebitor.postalCode,
            postalPlace: state.selectedDebitor.postalPlace,
            countryCode: state.selectedDebitor.countryCode,
            caseMessage: '',
        }
        console.log('Da er formvalues satt: ', formValues)
    }

    const produktOptions = () => {
        return (
            <>
                <option value="">Velg</option>
                <option value="Purring">Inkassovarsel</option>
                <option value="Inkasso">Inkasso</option>
            </>
        )
    }

    const debtorTypeOptions = () => {
        return (
            <>
                <option value="">Velg</option>
                <option value="Næring">Næring</option>
                <option value="Privat">Privat</option>
            </>
        )
    }

    const countryOptions = () => {
        return (
            <>
                <option key={-1} value={""}>Velg land</option>
                {state.countryList.map((item, i) => (
                    <option key={i} value={item.countryCode}>{item.country}</option>
                ))}
            </>
        )
    }
    

    // -----------------------------------------------------------------
    // start: Sett opp en ucForm med formvalues og validators
    // -----------------------------------------------------------------

    const [extraErrors, setExtraErrors] = useState({})
    const [errorMessage, setErrorMessage] = useState('')

    let formValidators = null
    if (state.existingDebitor) {
         // TODO: Sende inn customerno listen
         formValidators = GetFormValidators(localeMessages)
    } else {
        formValidators = GetFormValidatorsNew(localeMessages)
    }
        
    const {
        handleChange, handleCheckboxChange, setField, handleMultiSelectChange,
        formState, validateField, validateAll, errorMessages,
        formIsValid, validatorsState
    } = useUcForm(formValues, formValidators)
    
    const hasErrors = Object.values(errorMessages).some(x => (x !== null && x !== '')) || Object.values(extraErrors).some(x => (x !== null && x !== ''));

    const handlePnrChange = (e) => {

        if (e.target.value.length === 4) {
            let res = state.postalList.filter(x => x.postalCode === e.target.value)
            if (res.length === 1) {
                setField('postalPlace', res[0].postalPlace)
            }
            console.log(res)
        }
        handleChange(e)
    }

    // -----------------------------------------------------------------
    // slutt: Sett opp en ucForm med formvalues og validators
    // -----------------------------------------------------------------

    const [debtorWithReference, setDebtorWithReference] = useState(null)

    const newDebtorCheckReference = (refCustId) => {
        // etter at man går ut fra referansefeltet skal denne kjøre

        setDebtorWithReference(null)
        console.log(refCustId)
    }
    
    const existingDebtorCheckReference = (refCustId) => {
        // etter at man går ut fra referansefeltet skal denne kjøre

        setDebtorWithReference(null)
        console.log(refCustId)
    }


    const getBirthDateOrFnr = (formState) => {
        if (formState.birthNumber) {
            return formState.birthNumber.replace(/\s+/g, '')
        }
        else if (formState.birthDate) {
            return formState.birthDate.replaceAll('.', '')
        } else {
            return ''
        }
    }

    const stripSpaces = (inString) => {
        if (inString) {
            let result = inString.replace(/\s+/g, '') 
            return result
        }
        return ''
    }

    const submitForm = () => {
    
        // Valider alle
        let formDontValidate = validateAll();

        // Sjekk felter som ikke valideres på cursor
        setExtraErrors({})

        // Test på om det finnes error
        if (formDontValidate) {
            console.log('cannot be submitted')
        } else {
            console.log('Canbesubmitted')

            // Dispatch lagre og motta midlertidig id
            console.log('Lagre debitor. Gå til fakturaliste')

            // http://localhost:3000/#/CaseDetails/000002/70l6qg

            // Her må du opprette ny debitor, send inn dispatch vis fakturaslist
            let formData = {
                CreditorNo: state.kreditorId,
                DepartmentId: -1,
                produktType: formState.produktType,  
                referanseNummer: formState.referanseNummer,
                birthDate: (formState.debtorType === 'Næring') ? '' : getBirthDateOrFnr(formState),
                //debtorIdentificationNumber: (formState.debtorType === 'Næring') ? formState.debtorIdentificationNumber.replace(/\s+/g, '') : '',
                debtorIdentificationNumber: (formState.debtorType === 'Næring') ? stripSpaces (formState.debtorIdentificationNumber) : '',
                debtorName: formState.debtorName,
                firstName: formState.firstName,
                lastName: formState.lastName,
                debtorNo: formState.debtorNo,
                debtorType: formState.debtorType,
                email: formState.email,
                phone: formState.phone,
                streetAddress: formState.streetAddress,                
                postalAddress: formState.postalAddress,
                postalCode: formState.postalCode,
                postalPlace: formState.postalPlace,
                countryCode: formState.countryCode,
                caseMessage: formState.caseMessage,
            }

            console.log (formData)
        
            dispatch(postNySakDebitor(formData, true, localeMessages))
            
        }
    }

    const clearForm = () => {      
    }

    const postForm = () => {
        
    }

    const debugInfo = () => {       
    }

    // -----------------------------------------------------------------
    // Returnerer verdier/funcs som skal benyttes i formen
    // -----------------------------------------------------------------

    return {
        formValues, formValidators, handleChange, handleBlur, formState, validateAll, setField,
        hasErrors, errorMessages, extraErrors, setExtraErrors,
        submitForm, errorMessage, localeMessages, produktOptions, debtorTypeOptions, countryOptions, handlePnrChange,
        newDebtorCheckReference, existingDebtorCheckReference, debtorWithReference,
        confirmModalVisible, confirmModalOk, confirmModalCancel, altDebitor
    }
}

export default useDebitorForm