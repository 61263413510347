import React, { useState } from 'react';
import { Container, Col, Form, Alert, Spinner } from "react-bootstrap";
import { BfColInput } from '../../../Styling/FormComponents/FormInput'
import { BfYellowRowForm, BfTightYellowRowForm, BfButtonBlue, BfRowSpacer, YellowRoundedContainer } from '../../../Styling/styledForm/styledBlueForm'
import { ArticleArea } from '../../../Styling/Layout/ContentArea';
import useDebitorForm from './useDebitorForm';
import { useHistory } from 'react-router-dom';
import { ConfirmModal } from '../../../components/confirmModal/ConfirmModal';
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components';
import { useAuth } from '../../../hooks/useAuth';
import Axios from 'axios'
import { toast } from 'react-toastify';

const FieldLabel = styled.label`
  display: block;
  font-weight: 400;
`;

const Circle = styled.button`    
    background: ${props => props.primary || "white"};
    width: 23px;
    height: 23px;
    border-radius: 50%;
    border: 1px solid black;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

const DebitorForm = (props) => {

    const {
        formValues, formValidators, handleChange, handleBlur, formState, validateAll, setField,
        hasErrors, errorMessages, extraErrors, setExtraErrors,
        submitForm, errorMessage, localeMessages, produktOptions,
        debtorOptions, debtorTypeOptions, countryOptions, handlePnrChange,
        newDebtorCheckReference, existingDebtorCheckReference, debtorWithReference,
        confirmModalVisible, confirmModalOk, confirmModalCancel, altDebitor
    } = useDebitorForm(props.state, props.dispatch, props.showToasts, props.localeMessages )


    const auth = useAuth()

    const history = useHistory();
    const state = props.state;
    let selectedKreditor = state.kreditorId;
    const [isLoading, setIsLoading] = useState(false)

    const getFormField = (colspan, fieldname, inputType = 'text', selectOptions = '', disabled = false, maxLength = 524288) => {
        return (
            <BfColInput
                colspan={colspan}
                name={fieldname}
                inputType={inputType}
                onChange={handleChange}
                errorMessages={errorMessages}
                extraErrors={extraErrors}
                messages={localeMessages}
                required
                formState={formState}
                selectOptions={selectOptions}
                disabled={disabled}
                maxLength={maxLength}
                onBlur={handleBlur}
            />
        )
    }

    const getFormFieldPnr = (colspan, fieldname, inputType = 'text', selectOptions = '', disabled = false, maxLength = 524288) => {
        return (
            <BfColInput
                colspan={colspan}
                name={fieldname}
                inputType={inputType}
                onChange={handlePnrChange}
                errorMessages={errorMessages}
                extraErrors={extraErrors}
                messages={localeMessages}
                required
                formState={formState}
                selectOptions={selectOptions}
                disabled={disabled}
                maxLength={maxLength}
            />
        )
    }

    const pStyle = {
        width: '80%'
    }

    const disableExisting = () => {
        return true;
    }

    const getPrivateRow = (disabled) => {

        if (disabled) {
            return (
                <>
                    {getFormField(3, 'firstName', null, null, disabled, 40)}
                    {getFormField(3, 'lastName', null, null, disabled, 40)}
                    {getFormField(2, 'birthDate', 'cleaveDate', null, disabled)}
                    {getFormField(2, 'birthNumber', 'cleaveFnrNr', null, disabled)}

                    {formState.debtorNo &&
                        getFormField(2, 'debtorNo', null, null, props.disabled)}
                </>
            )
        }
        else {
            return (
                <>
                    {getFormField(3, 'firstName', null, null, disabled, 40)}
                    {getFormField(3, 'lastName', null, null, disabled, 40)}
                    {getFormField(2, 'birthDate', 'cleaveDate', null, disabled)}
                    {getFormField(2, 'birthNumber', 'cleaveFnrNr', null, disabled)}
                    {formState.debtorNo &&
                        getFormField(2, 'debtorNo', null, null, props.disabled)}
                </>
            )
        }

    }

    const getNaeringRowRow = () => {
        return (
            <>
                {getFormField(2, 'debtorIdentificationNumber', 'cleaveOrgNr', null, props.disabled)}
                {getFormField(6, 'debtorName', null, formState.debtorName, props.disabled, 40)}
                {formState.debtorNo &&
                    getFormField(2, 'debtorNo', null, null, props.disabled)}
                {props.showInfoButton && <BfButtonBlue 
                    style={{width:'50px', marginTop: '31px', marginLeft: '10px', height: '40px',  }} 
                    variant="primary" 
                    type="button" 
                    onClick={() => getFirmaInfo(formState.debtorIdentificationNumber)}
                    disabled={formState.debtorIdentificationNumber.replace(/\s/g, "").length < 9}>
                    {localeMessages.HentInfo}
                </BfButtonBlue>}
            </>
        )
    }

    const showConfirmModal = () => {
        const setHeader = `${localeMessages.md_Header1}: ${altDebitor.firstName} ${altDebitor.lastName}`
        return (
            <ConfirmModal
                okHandler={confirmModalOk}
                cancelHandler={confirmModalCancel}
                message={localeMessages.md_Message}
                header={setHeader}
                okCaption={localeMessages.md_Ok}
                cancelCaption={localeMessages.md_Cancel}
                data={altDebitor}
            >
            </ConfirmModal>
        )
    }

    const dispatch = useDispatch()
    const showToasts = true

    const getFirmaInfo = (orgNr) => {
        const orgNrUtenWhitespace = orgNr.replace(/\s/g, "")

        setIsLoading(true)
    
        Axios
            ({
                method: 'GET',
                url: `https://data.brreg.no/enhetsregisteret/api/enheter/${orgNrUtenWhitespace}`
            })
            .then((result) => {
    
                if (result.status !== 200) {
                    (showToasts && toast.error(localeMessages['toastFirmainfoError'], {autoClose: false}))                    
                } else {
                    (showToasts && toast.warning(localeMessages['toastFirmaIntoHentet']))
                    setField('postalAddress', '');
                    setField('streetAddress', '');
                    setField('debtorName', result.data.navn)
    
                    if (result.data.postadresse && result.data.postadresse.adresse && result.data.postadresse.adresse.length > 0) {
                        const addresses = result.data.postadresse.adresse;
                        const formattedAddress = addresses.length > 1 
                            ? addresses[1] 
                            : addresses[0];
                        setField('postalAddress', formattedAddress);
                        if (result.data.forretningsadresse && result.data.forretningsadresse.adresse && result.data.forretningsadresse.adresse.length > 0) {
                            const addresses = result.data.forretningsadresse.adresse;
                            const formattedAddress = addresses.length > 1 
                                ? addresses[1] 
                                : addresses[0];
                            setField('streetAddress', formattedAddress);
                        }
                    } else if (result.data.forretningsadresse.adresse && result.data.forretningsadresse.adresse.length > 0) {
                        setField('postalAddress', result.data.forretningsadresse.adresse[0]);
                    } else {
                        setField('postalAddress', '');
                    }
                    
                    setField('postalCode', result.data.forretningsadresse.postnummer)
                    setField('postalPlace', result.data.forretningsadresse.poststed)                            
                }       
                setIsLoading(false)         
            })
            .catch((err) => {
                (showToasts && toast.error(localeMessages['toastFirmainfoError'] + orgNr, {autoClose: false}))
                setIsLoading(false)                
                console.log(err)                
            })
    
    }

    return (
        <Container fluid style={pStyle}>

            {confirmModalVisible && showConfirmModal()}

            <ArticleArea>
                <h1>{localeMessages.SK_Head}</h1>
                <p>{localeMessages.SK_Caption}</p>

            </ArticleArea>

            <Form autocomplete="chrome-off">
                <YellowRoundedContainer fluid style={{ padding: '15px' }}>

                    {hasErrors && (
                        <Alert fluid variant='danger'>
                            {localeMessages.youHaveErrors}
                        </Alert>
                    )}

                    <BfYellowRowForm>
                        {getFormField(4, 'produktType', 'select', produktOptions)}
                        {getFormField(8, 'referanseNummer', null, null, false, 32)}
                    </BfYellowRowForm>

                </YellowRoundedContainer>
                <BfRowSpacer>

                </BfRowSpacer>

                <YellowRoundedContainer fluid style={{ padding: '15px' }}>


                    <BfYellowRowForm>
                        {getFormField(2, 'debtorType', 'select', debtorTypeOptions, props.disabled)}

                        {formState.debtorType === 'Næring' &&
                            getNaeringRowRow()
                        }
                        {formState.debtorType === 'Privat' &&
                            getPrivateRow(props.disabled)
                        }
                        {formState.debtorType === '' &&
                            getPrivateRow(true)
                        }


                    </BfYellowRowForm>

                    <BfYellowRowForm>
                        {getFormField(4, 'postalAddress', null, null, props.disabled, 63)}
                        {getFormField(4, 'streetAddress', null, null, props.disabled, 63)}
                        {getFormField(4, 'email', null, null, props.disabled, 63)}
                    </BfYellowRowForm>

                    <BfYellowRowForm>
                        {getFormFieldPnr(2, 'postalCode', null, null, props.disabled, 10)}
                        {getFormField(4, 'postalPlace', null, null, props.disabled, 30)}
                        {getFormField(2, 'countryCode', 'select', countryOptions, props.disabled)}

                        {getFormField(3, 'phone', null, null, props.disabled, 15)}
                    </BfYellowRowForm>

                    <BfYellowRowForm>
                        {getFormField(12, 'caseMessage', 'textarea')}
                    </BfYellowRowForm>

                    <BfTightYellowRowForm>
                        <Col xs={12} md={12}>
                            <BfButtonBlue variant="primary" type="button" onClick={submitForm}>
                                {localeMessages.Lagre}
                            </BfButtonBlue>

                            &nbsp;

                            <BfButtonBlue variant="primary" type="button" onClick={() => history.push("/CaseNew/init")}>
                                {localeMessages.butTilbake}
                            </BfButtonBlue>
                            &nbsp;

                        </Col>
                    </BfTightYellowRowForm>

                    <BfTightYellowRowForm />

                </YellowRoundedContainer>
            </Form>
        </Container>
    )
}

export default DebitorForm

