import React, { useState, useEffect } from 'react';
import { StyledKfTextTable, StyleInlineFkTable } from '../../../Styling/styledTable/StyledTable';
import UseTableSorter from '../../../components/useTable/UseTable'
import usePaginator, { StyledDropdown } from '../../../components/useTable/UsePaginator'
import {Pagination, Dropdown } from 'react-bootstrap'
import { FakturaHeaderComponent } from './FakturaHeaderComponent';
import { FakturaRowComponent } from './FakturaRowComponent';
import { Button } from 'react-bootstrap';
import { BfButtonBlue, BfCheckboxContainer2, BfCheckboxInput2, BfCheckboxText } from '../../../Styling/styledForm/styledBlueForm';
import { ExcelSheet } from './FaExcelWorksheet';
import { FakturaMonthSelector } from './FakturaMonthsSelector';
import { dateToStringDMYHR } from '../../../util/date/dateRoutines';

const FakturaAdminTabell = (props) => {

    const localeMessages = props.localeMessages

    // State for grid sorting
    const [currentSeq, setCurrentSeq] = useState('down') // default
    const [currentField, setCurrentField] = useState('fileDate')    // feltnavn
    const [currentType, setCurrentType] = useState('datetime') // num

    // state for grid paginator
    const [numberPrPage, setNumberPrPAge] = useState(10)
    const [activePage, setActivePage] = useState(1);

    const [showHiddenRows, setShowHiddenRows] = useState(false);
    const [passedDueDateData, setPassedDueDateData] = useState([]);

    const [t1ChangeSortField, t1GetSortFunc, t1IndicaterIcon] = UseTableSorter(
        currentSeq, setCurrentSeq,
        currentField, setCurrentField,
        currentType, setCurrentType
    )

    // Point to data 
    const tableData = props.tableData
    const invMonths = props.invMonths
    const setInvMonths = props.setInvMonths
    const selectorValues = props.selectorValues

    useEffect(() => {
        const filteredList = props.tableData.filter((item) => item.passedDueDate === true);
    
        setPassedDueDateData(filteredList);
      }, [props.tableData]);

      // Kan potensielt håndteres i reducer, men why change a winning team osv.
      const dataToPaginate = showHiddenRows ? props.tableData : passedDueDateData;
   
    // Setupt paginator
    const [Paginate, PaginationNav, PaginationDropdown, PaginationIndex, resetPagination] = usePaginator(
        dataToPaginate.sort(t1GetSortFunc().fn), // <==== sorting inputdata =========
        numberPrPage, setNumberPrPAge,
        activePage, setActivePage
    )

    const getContentOverTabellen = (state) => {
        let _kisIntegration = "(ikke oppgitt)"
        let _apiTimeStamp = "(ikke oppgitt)"
        if (state.kisIntegration)
            _kisIntegration = state.kisIntegration

        if (state.apiTimeStamp)
        {
            let ts = state.apiTimeStamp
            _apiTimeStamp = dateToStringDMYHR(ts)
        }

        return (<>
            innlastet: <b>{_apiTimeStamp}</b>,&nbsp;
            fra: <b>{_kisIntegration}</b>
        </>)
    }

    const handleCheckboxChange = (event) => {
        setShowHiddenRows(!event.target.checked);
        props.toggleOnlyPassedDueDate(!event.target.checked);        
      };

    return (
        <>
            {props.tableData.length !== 0 && <>
                <PaginationDropdown />
                    <FakturaMonthSelector 
                        invMonths={invMonths}
                        setInvMonths={setInvMonths}
                        selectorValues={selectorValues}
                        messages={localeMessages}
                /> 
                {/* 
                fra: <b>{props.state.kisIntegration}</b>XXX
                */}
                {getContentOverTabellen(props.state)}
                &nbsp;&nbsp;&nbsp;
                <BfCheckboxContainer2>
                    <BfCheckboxInput2
                        checked={!showHiddenRows}
                        onChange={handleCheckboxChange}
                    />
                    <BfCheckboxText>{localeMessages.checkboxText}</BfCheckboxText>
                </BfCheckboxContainer2>         
            </>}

            <StyledKfTextTable>
                <FakturaHeaderComponent
                    localeMessages={localeMessages}
                    t1ChangeSortField={t1ChangeSortField}
                    t1IndicaterIcon={t1IndicaterIcon}

                />

                <tbody>
                    <Paginate>
                        {/* paginate forventer en funksjon */}
                        {(row, i) => (<FakturaRowComponent row={row} toggleRow={props.toggleRow} />)}
                    </Paginate>
                </tbody>

            </StyledKfTextTable>

            {props.tableData.length !== 0 &&
                <>
                    <br />
                    <PaginationIndex />
                    <br /><br />
                    <PaginationNav />
                    

                    <BfButtonBlue variant="primary" type="button" onClick={() => props.toggleAllRows(true)}>
                        {localeMessages.MerkAlle}
                    </BfButtonBlue>{' '}
                    
                    <BfButtonBlue variant="primary" type="button" onClick={() => props.toggleAllRows(false)}>
                        {localeMessages.MerkIngen}
                    </BfButtonBlue>{' '}
                    

                    <ExcelSheet
                        filename={props.selectedKreditor}
                        headerState={props.headerState}
                        data={props.state.invoiceList}   
                        localeMessages={props.localeMessages}
                    />{' '}     
                    
                    <BfButtonBlue variant="primary" type="button"
                        disabled={props.state.antall_valgte === 0}
                        onClick={() => props.bsFuncs.showDialog()}>
                        {localeMessages.Send}
                    </BfButtonBlue>{' '}          
                
                </>
            }

        </>
    )
}

export default FakturaAdminTabell



