export const messages = {
    nb: {
        header: `Søk etter brukerprofiler`,
        beskrivelse: `Velg søkefelt, skriv inn kriterier og trykk Søk for å søke.`,
        SokOk: `Søk`,
        Nullstill: `Nullstill`,

        velgFraListe: 'Velg søkefelt',
        brukerNavn: 'Fullt navn',
        epostAdresse: 'Epostadresse (brukernavn)',
        kundenummer: 'Kundenummer',
        kundenavn: 'Kundenavn',
        orgNummer: 'Organisasjonsnummer', 

        SearchFieldLabel: `Søkefelt`,
        SearchFieldPlaceholder: `Velg felt`,
        SearchFieldSmall: `Velg hvilket felt du vil søke i.`,

        SearcValueLabel: `Søk etter`,
        SearcValuePlaceholder: `Skriv inn kriterier`,
        SearcValueSmall: `Oppgi søkekriterier. Bruk "%" som jokertegn. Eks: "ma%" finner alle som begynner med "ma".`,

        OpprettNy: 'Ny bruker',
        KopierMeg: 'Ny m/mine kreditorer'
    }
} 
