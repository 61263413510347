import React from 'react'
import { Container, Col, Row } from "react-bootstrap";
import styled from 'styled-components'
import { FormattedNumber } from 'react-intl'

const StyledCol = styled(Col)`
    border-width: 0 0 0 5px;
    border-color:  rgb(0, 79, 113);
    border-style: solid;
    margin-bottom: 20px;
`

const StyledColR = styled(Col)`
    border-width: 0 5px 0 5px;
    border-color:  rgb(0, 79, 113);
    border-style: solid;
    margin-bottom: 20px;
`
const StyledBox = styled.div`
    margin-right: 50px;
   
    height: 68px;
    background-color: white;
    margin-bottom: 0px;

    font-size: 20px;
    font-weight: 400;
    color: rgb(0, 79, 113)
`

const StyledBoxHeader = styled.p`
    font-size: 17px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 4px;
`

const StyledBoxNumber = styled.p`
    font-size: 45px;
    line-height: 45px;
    font-weight: 400;
    margin: 0px;
    padding: 0px;
`

const FakturaAdminTotals = (props) => {

    const state = props.state
    const messages = props.messages
    
    return (
        <Container fluid="true">
            <Row>

                <StyledCol xs lg="1">
                    <StyledBox>
                        <StyledBoxHeader>{messages.antall}</StyledBoxHeader>
                        <StyledBoxNumber>
                            {<FormattedNumber
                                value={state.antall}
                                style='decimal'
                                minimumFractionDigits={0}
                                maximumFractionDigits={0}
                            />}
                        </StyledBoxNumber>
                    </StyledBox>
                </StyledCol>

                <StyledCol xs lg="2">
                    <StyledBox>
                        <StyledBoxHeader>{messages.sum_alle}</StyledBoxHeader>
                        <StyledBoxNumber>
                            {<FormattedNumber
                                value={state.sum_alle}
                                style='decimal'
                                minimumFractionDigits={2}
                                maximumFractionDigits={2}
                            />}
                        </StyledBoxNumber>
                    </StyledBox>
                </StyledCol>


                <StyledCol xs lg="2">
                    <StyledBox>
                        <StyledBoxHeader>{messages.antall_valgte}</StyledBoxHeader>
                        <StyledBoxNumber>
                            {<FormattedNumber
                                value={state.antall_valgte}
                                style='decimal'
                                minimumFractionDigits={0}
                                maximumFractionDigits={0}
                            />}
                        </StyledBoxNumber>
                    </StyledBox>
                </StyledCol>

                <StyledCol xs lg="2">
                    <StyledBox>
                        <StyledBoxHeader>{messages.antall_saker_valgte}</StyledBoxHeader>
                        <StyledBoxNumber>
                            {<FormattedNumber
                                value={state.antall_saker_valgte}
                                style='decimal'
                                minimumFractionDigits={0}
                                maximumFractionDigits={0}
                            />}
                        </StyledBoxNumber>
                    </StyledBox>
                </StyledCol>

                <StyledCol xs lg="2">
                    <StyledBox>
                        <StyledBoxHeader>{messages.sum_valgte}</StyledBoxHeader>
                        <StyledBoxNumber>
                            {<FormattedNumber
                                value={state.sum_valgte}
                                style='decimal'
                                minimumFractionDigits={2}
                                maximumFractionDigits={2}
                            />}
                        </StyledBoxNumber>
                    </StyledBox>
                </StyledCol>

                <StyledCol xs lg="1">
                    <StyledBox>
                        <StyledBoxHeader>{messages.antall_ikke_valgte}</StyledBoxHeader>
                        <StyledBoxNumber>
                            {<FormattedNumber
                                value={state.antall_ikke_valgte}
                                style='decimal'
                                minimumFractionDigits={0}
                                maximumFractionDigits={0}
                            />}
                        </StyledBoxNumber>
                    </StyledBox>
                </StyledCol>

                <StyledColR xs lg="2">
                    <StyledBox>
                        <StyledBoxHeader>{messages.sum_ikke_valgte}</StyledBoxHeader>
                        <StyledBoxNumber>
                            {<FormattedNumber
                                value={state.sum_ikke_valgte}
                                style='decimal'
                                minimumFractionDigits={2}
                                maximumFractionDigits={2}
                            />}
                        </StyledBoxNumber>
                    </StyledBox>
                </StyledColR>
            </Row>
        </Container>
    )
}

export default FakturaAdminTotals
