import React, { useState, useEffect } from 'react';
//import axios from 'axios'
import { useUcForm } from '../../hooks/useUcForm'
import { toast } from 'react-toastify';
import _ from 'lodash'
import { resetPasswordValidators } from './resetPasswordValidators';
import authInstance from '../../Instances/axios-auth'
import { useHistory } from "react-router-dom";


export const useResetPassword = (localeMessages) => {

    let history = useHistory()

    const showToasts = true

    const [isLoading, setIsLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    let formValues = {       
        Epost: '',
        Token: '',
        Passord1: '',
        Passord2: '',
    }

    const formValidators = resetPasswordValidators(localeMessages)

    const {
        handleChange, handleCheckboxChange, setField, handleMultiSelectChange, formState, validateField,
        validateAll, errorMessages, formIsValid,
        validatorsState } = useUcForm(formValues, formValidators)

    const [extraErrors, setExtraErrors] = useState({})
    // hasErrors gjorde knappen aktiv om Nytt passord var fylt ut, men ikke Gjenta nytt passord var fylt ut. Og om Nytt og Gjenta ikke var fylt ut.
    const hasErrors = Object.values(errorMessages).some(x => (x !== null && x !== '')) || (!formState.Password1 && !formState.Password2) || (formState.Password1 && !formState.Password2) ||  Object.values(extraErrors).some(x => (x !== null && x !== ''));

    //useEffect(() => {
        //setField('Navn', auth.user.name)
        //setField('Epost', auth.user.email)
    //}, [])

    const submitForm = () => {

        let formDontValidate = validateAll();

        /*
        if (formState.Passord1 || formState.Passord2) {
            if (!formState.Passord1 || !formState.Passord2) {
                setErrorMessage(localeMessages['youHavePWErrors'])
                return
            }
        }*/

        // Sjekk felter som ikke valideres på cursor
        setExtraErrors({})


        // Test på om det finnes error
        if (formDontValidate) {
            console.log('cannot be submitted')
        } else {
            console.log('Canbesubmitted')

            setIsLoading(true)
            setErrorMessage('')

            //const formData = new FormData();
            //formData.append('Token', formState.Token);
            //formData.append('Email', formState.Epost);
            //formData.append('Password', formState.Password1);
            ////formData.append('Password2', formState.Passord1);

            const formData = {
                Token: formState.Token,
                Email: formState.Epost,
                Password: formState.Password1,                
            }

            console.log(JSON.stringify(formData))

            authInstance.post
                ( 'api/ApiSecurity/resetpassword', formData)
                .then((result) => {
                    setIsLoading(false)
                    console.log(result)

                    if (result.status !== 200) {
                        (showToasts && toast.error(localeMessages['toastSaveError'], {autoClose: false}))
                    } else {
                        (showToasts && toast.warning(localeMessages['toastSaveOk']))                        
                        console.log(JSON.stringify(result.data))
                        //setState({ redirect: "/login" });
                        history.push("/login");
                    }
                })
                .catch((err) => {
                    setIsLoading(false)
                    //loadash is better than if (err.response.status === 400) {
                    if (_.has(err, 'response.data')) {
                        setErrorMessage(localeMessages['toastSaveError'] + err.response.data)
                        console.log(err)
                    } else {
                        setErrorMessage(localeMessages['toastSaveError'] + ', statuscode: ' + err.message)
                        console.log(err)
                    }
                    console.log(errorMessage)
                })
        }        

    }

    const debugInfo = () => {
        if (process.env.NODE_ENV == 'development') {
            return (
                <>
                    errors: {JSON.stringify(errorMessages)}<br />
                        ekstraErrors: {JSON.stringify(extraErrors)}<br />
                    { JSON.stringify(formState, null, "\n")}
                </>
            )
        }
    }

    return { setField, formValues, formValidators, handleChange, formState, validateAll, hasErrors, errorMessages, extraErrors, setExtraErrors, submitForm, isLoading, errorMessage, debugInfo }

}
