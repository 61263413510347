import React, { useState } from 'react';
import { FormattedDate, FormattedNumber } from 'react-intl'
import { StyleInlineFkTable } from './../../../Styling/styledTable/StyledTable';
import UseTableSorter from '../../../components/useTable/UseTable'
import usePaginator from '../../../components/useTable/UsePaginator'
import OversiktContext from '../OversiktContext';
import { fixMissingTimeFromDate } from '../../../util/date/dateRoutines';

const FakturaListe = (props) => {

    //const localMessages = props.localeMessages
    const { localeMessages } = React.useContext(OversiktContext);

    // State for grid sorting
    const [currentSeq, setCurrentSeq] = useState('down'); // default
    const [currentField, setCurrentField] = useState('regDate');    // feltnavn
    const [currentType, setCurrentType] = useState('datetime'); // num

    // state for grid paginator
    const [numberPrPage, setNumberPrPAge] = useState(10000)
    const [activePage, setActivePage] = useState(1);


    const [t1ChangeSortField, t1GetSortFunc, t1IndicaterIcon] = UseTableSorter(
        currentSeq, setCurrentSeq,
        currentField, setCurrentField,
        currentType, setCurrentType
    )

    // Point to data 
    const tableData = props.tableData

    // Setupt paginator
    const [Paginate, PaginationNav, PaginationDropdown, PaginationIndex, resetPagination] = usePaginator(
        tableData.sort(t1GetSortFunc().fn), // <==== sorting inputdata =========
        numberPrPage, setNumberPrPAge,
        activePage, setActivePage
    )

    const printHeader = () => {
        return (
            <thead>
                <tr>
                    <th onClick={() => t1ChangeSortField('linenumber', 'num')}>{localeMessages['GHF_linenumber']}{t1IndicaterIcon('linenumber')}</th>
                    <th onClick={() => t1ChangeSortField('invoiceDate', 'datetime')}>{localeMessages['GHF_invoiceDate']}{t1IndicaterIcon('invoiceDate')}</th>
                    <th onClick={() => t1ChangeSortField('dueDate', 'datetime')}>{localeMessages['GHF_dueDate']}{t1IndicaterIcon('dueDate')}</th>
                    <th onClick={() => t1ChangeSortField('invoiceNumber', 'string')}>{localeMessages['GHF_invoiceNumber']}{t1IndicaterIcon('invoiceNumber')}</th>
                    <th onClick={() => t1ChangeSortField('description', 'string')}>{localeMessages['GHF_description']}{t1IndicaterIcon('description')}</th>
                    
                    <th style={{ textAlign: "right" }} onClick={() => t1ChangeSortField('originalCapital', 'num')}>{localeMessages['GHF_originalCapital']}{t1IndicaterIcon('originalCapital')}</th>
                    <th style={{ textAlign: "right" }} onClick={() => t1ChangeSortField('paidCapital', 'num')}>{localeMessages['GHF_paidCapital']}{t1IndicaterIcon('paidCapital')}</th>
                    <th style={{ textAlign: "right" }} onClick={() => t1ChangeSortField('interest', 'num')}>{localeMessages['GHF_interest']}{t1IndicaterIcon('interest')}</th>
                    <th style={{ textAlign: "right" }} onClick={() => t1ChangeSortField('balanceInterest', 'num')}>{localeMessages['GHF_balanceInterest']}{t1IndicaterIcon('balanceInterest')}</th>
                    <th style={{ textAlign: "right" }} onClick={() => t1ChangeSortField('balanceCapital', 'num')}>{localeMessages['GHF_balanceCapital']}{t1IndicaterIcon('balanceCapital')}</th>
            </tr>
            </thead>
        )
    }

    const printRow = (model) => {

        const asDate = (val) => {
            // Kludge fordi FireFox ikke godtar dato uten tid uten videre
            let valok = fixMissingTimeFromDate(val)
            return (
                <FormattedDate value={valok} day="2-digit" month="2-digit" year="numeric" />
            )
        }


        return (
            <>
                <tr key={model.lineNumber+ 10000}>

                    <td>{model.lineNumber}</td>
                    <td>{asDate(model.invoiceDate)}</td>
                    <td>{asDate(model.dueDate)}</td>                   
                    <td>{model.invoiceNumber}</td>
                    <td>{model.description}</td>

                    <td align="right">
                        {<FormattedNumber 
                            value={model.originalCapital}
                            style='decimal'
                            minimumFractionDigits={2}
                        />}
                    </td>

                    <td align="right">
                        {<FormattedNumber
                            value={model.paidCapital}
                            style='decimal'
                            minimumFractionDigits={2}
                        />}
                    </td>

                    <td align="right">
                        {<FormattedNumber
                            value={model.interestRate}
                            style='decimal'
                            minimumFractionDigits={2}
                        />}
                    </td>

                    <td align="right">
                        {<FormattedNumber
                            value={model.balanceInterest}
                            style='decimal'
                            minimumFractionDigits={2}
                        />}
                    </td>

                    <td align="right">
                        {<FormattedNumber
                            value={model.balanceCapital}
                            style='decimal'
                            minimumFractionDigits={2}
                        />}
                    </td>

                </tr>

            </>

        )
    }

    return (
        <>

            <StyleInlineFkTable>
                {printHeader()}

                <tbody>
                    <Paginate>
                        {/* paginate forventer en funksjon */}
                        {row => printRow(row)}
                    </Paginate>
                </tbody>
            </StyleInlineFkTable>            
           
        </>
    )
}

export default FakturaListe
