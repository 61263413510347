import React from 'react';
import { Container, Col, Form, Alert } from "react-bootstrap";
import { BfColInput } from '../../Styling/FormComponents/FormInput';
import { BfRowForm, BfTightRowForm, BfButtonBlue } from '../../Styling/styledForm/styledBlueForm'
import colorConfig from '../../colorConfig'
import styled from 'styled-components'

const RoundedContainer = styled(Container)`
    background-color: ${colorConfig.tabell_lysGul};  
    color: black;
    border-bottom: 0px;  
    padding: 20px 0px 20px 0px;      
    border: 0px solid black;
    border-radius: 0.5em;
    font-family: "Fellix", "sans-serif";
    font-size: 20px !important;

    input {   
        padding: 20;
        font-family: "Fellix", "sans-serif";
        font-size: 20px !important; 
        border-radius: 0.5em;
        border: 0px solid black;  

        *:focus {
           outline: none; !important; 
        }
    }

    .form-control {
        border-radius: 0.5em; !important; 
    }

    .form-control:focus {
        border-color: inherit;
        -webkit-box-shadow: none;
        box-shadow: none;
        border-radius: 0.5em;
    }

    .alert-danger {
        color: ${colorConfig.tabell_lysGul}; !important; 
        background-color: ${colorConfig.danger_bg};
        border-color: none;
        font-family: "Fellix", "sans-serif";
        font-size: 20px !important; 
        border-radius: 0.5em;
        font-weight: 400;
    }
}
`

export const ResetPasswordForm = (props) => {

    const handleChange = props.handleChange
    const errorMessages = props.errorMessages
    const extraErrors = props.extraErrors
    const localeMessages = props.localeMessages
    const formState = props.formState
    const hasErrors = props.hasErrors
    const submitForm = props.submitForm

    const getFormField = (colspan, fieldname, inputType = 'text', selectOptions = '') => {
        return (
            <BfColInput
                colspan={colspan}
                name={fieldname}
                inputType={inputType}
                onChange={handleChange}
                errorMessages={errorMessages}
                extraErrors={extraErrors}
                messages={localeMessages}
                required
                formState={formState}
                selectOptions={selectOptions}
            />
        )
    }


    return (
        <RoundedContainer>
            <Form autoComplete="chrome-off" style={{ maxWidth: "1000px", margin: "10px 20px 10px 20px" }}>

               
            
            {/* 
            Denne trigger i det bruker skriver noe i Nytt passord feltet, brukere har rapportert det som forvirrende
            {hasErrors && (
                <Alert variant='danger'>
                    {localeMessages.youHaveErrors}
                </Alert>
            )} */}

                <BfRowForm>
                    {getFormField(12, 'Password1', 'password')}
                </BfRowForm>

                <BfRowForm>
                    {getFormField(12, 'Password2', 'password')}
                </BfRowForm>

                <BfTightRowForm>
                    <Col xs={12} md={12}>
                        <BfButtonBlue variant="primary" type="button"
                            onClick={submitForm} disabled={hasErrors}>
                            {localeMessages.Lagre}
                        </BfButtonBlue>
                    </Col>
                </BfTightRowForm>

                <BfTightRowForm />
            </Form>
        </RoundedContainer>
    )
}