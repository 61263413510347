import React, { useEffect } from 'react';
import { IntlProvider } from 'react-intl'
import LocaleContext from '../../state/contexts/LocaleContext'
import { messages } from './resetPasswordContainer.i18n'
import { Spinner } from '../../components/spinner/Spinner'
import { ResetPasswordForm } from './RestetPasswordForm'
import { useResetPassword } from './useResetPassword'
import { Alert, Container } from "react-bootstrap"
import { ArticleArea } from '../../Styling/Layout/ContentArea'

/*

TODO:
_ Lag en i18 file
_ Lag en form file
_ Lag en validators file
_ Lag en use file med kall til server

 */

const ResetPasswordContainer = (props) => { 

    const locale = React.useContext(LocaleContext);
    const localeMessages = messages[locale]

    const {
        setField, handleChange, formState,
        hasErrors, errorMessages, extraErrors,
        submitForm, isLoading, errorMessage, debugInfo
    } = useResetPassword(localeMessages)

    useEffect(() => {
        setField('Token', props.token)
        setField('Epost', props.email)
    }, [])

    if (isLoading) {
        return (
            <Spinner></Spinner>
        )
    }

  return (
        <>
         <Container style={{ paddingTop: '15px', width: '1000px' }}>
            <IntlProvider locale={locale} messages={messages[locale]}>

                {(errorMessage !== '') && <Alert variant='danger'>{errorMessage}</Alert>}

                <ArticleArea style={{ padding: '15px', maxWidth: '1000px' }}>
                    <h1>{localeMessages.header}</h1>
                    <p>{localeMessages.beskrivelse}</p>
                </ArticleArea>

                <ResetPasswordForm
                    handleChange={handleChange}
                    errorMessages={errorMessages}
                    extraErrors={extraErrors}
                    localeMessages={localeMessages}
                    formState={formState}
                    hasErrors={hasErrors}
                    submitForm={submitForm}
                />
            </IntlProvider>
            {debugInfo()}
            </Container>
        </>
    )
}

export default ResetPasswordContainer
