import React, { useState } from 'react';
import { Form, Col, Button } from "react-bootstrap";
import {
    StyledModal,
    StyledModalBody,
    StyledModalFooter,

} from "../../../Styling/styledModal/styledModals"
import { BfButtonBlue } from '../../../Styling/styledForm/styledBlueForm'
import styled from 'styled-components';


const TooltipContainer = styled.div`
    position: relative;
    display: inline-block;
    cursor: pointer;

    .tooltip-text {
        visibility: hidden;
        font-size: 15px;
        width: 180px;
        background-color: #333;
        color: #fff;
        text-align: center;
        padding: 5px;
        border-radius: 4px;
        position: absolute;
        z-index: 1;
        bottom: 125%; /* Position above the button */
        left: 50%;
        transform: translateX(-50%);
        opacity: 0;
        transition: opacity 1.0s;
    }

    &:hover .tooltip-text {
        visibility: visible;
        opacity: 1;
    }
`;

const ArrowButton = styled.button`
    background: none;
    border: none;
    color: #F6EABE;
    cursor: pointer;
    display: flex;
    align-items: center;
`;

const ArrowIcon = styled.span`
    font-size: 26px;
    font-weight: 800;
    display: inline-block;
    margin-left: 8px;
    transition: transform 0.5s ease;
    transform: ${({ isExpanded }) => (isExpanded ? 'rotate(270deg)' : 'rotate(90deg)')};
`;

const ExpandableList = styled.div`
    max-height: ${({ isExpanded }) => (isExpanded ? '1000px' : '0')}; 
    opacity: ${({ isExpanded }) => (isExpanded ? '1' : '0')};
    overflow: hidden;
    transition: max-height 0.4s ease, opacity 0.4s ease;
    padding-left: ${({ isExpanded }) => (isExpanded ? '20px' : '20px')};
    margin-top: ${({ isExpanded }) => (isExpanded ? '10px' : '0')};
`;

const SearchResultModal = (props) => {

    const messages = props.deleteModal.messages
    const selectedRows = props.deleteModal.selectedRows

    const [isExpanded, setIsExpanded] = useState(true);

    const confirmMessage = () => (
        <TooltipContainer>
            <ArrowButton onClick={() => setIsExpanded(!isExpanded)}>
                {`Du har valgt ${selectedRows.length} ${selectedRows.length === 1 ? 'bruker' : 'brukere'} til sletting`}
                <ArrowIcon isExpanded={isExpanded}>›</ArrowIcon>
            </ArrowButton>
            <span className="tooltip-text">{isExpanded ? messages.HideUsers : messages.ExpandUsers}</span>
        </TooltipContainer>
    );
    
    const renderSelectedRows = () => (
        <ul>
            {selectedRows.map((row) => (
                <li key={row.id || row.i}>
                    {row.userName}, <b>{messages[row.krRolle]}</b>
                </li>
            ))}
        </ul>
    );

    return (

        <StyledModal centered show={true} onHide={props.deleteModal.cancelHandler}>
            <StyledModalBody style={{ width: '800px', maxHeight: '60vh', overflowY: 'auto' }}>
                <h4>{selectedRows.length === 1 ? messages.DeleteUser : messages.DeleteUsers}</h4>
                <p>{confirmMessage()}</p>
                <ExpandableList isExpanded={isExpanded}>{renderSelectedRows()}</ExpandableList>
            </StyledModalBody>

            <StyledModalFooter style={{ width: '800px' }}>
                <p style={{ width: '800px' }}>{messages.PressConfirm}</p>
                <BfButtonBlue
                    style={{ fontWeight: '700', marginRight: '10px' }}
                    variant="primary"
                    type="button"
                    onClick={props.deleteModal.okHandler}
                >
                    {messages.Confirm}
                </BfButtonBlue>
                <BfButtonBlue
                    style={{ fontWeight: '700' }}
                    variant="secondary"
                    type="button"
                    onClick={props.deleteModal.cancelHandler}
                >
                    {messages.Cancel}
                </BfButtonBlue>
            </StyledModalFooter>
        </StyledModal>

    );
};

export default SearchResultModal;